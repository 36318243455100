import { makeStyles } from "@material-ui/styles";

export default makeStyles(theme => ({
  root: {
    display: "flex",
    maxWidth: "100vw",
    overflowX: "hidden",
  },
  content: {
    flexGrow: 1,
    padding: theme.spacing(3),
    width: `calc(100vw - 240px)`,
    minHeight: "100vh",
  },
  contentShift: {
    width: `calc(100vw - ${240 + theme.spacing(6)}px)`,
    transition: theme.transitions.create(["width", "margin"], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
  },
  fakeToolbar: {
    ...theme.mixins.toolbar,
  },
  link: {
    '&:not(:first-child)': {
      paddingLeft: 15
    }
  },
  alertMsgContainer: {
    // position: "absolute",
    // // left: "40%",
    // left: "25px",
    // right: 0,
    // minWidth: "350px",
    // maxWidth: "fit-content",
    // top: "110px",
    // bottom: 0
  },
  alertMsgBox: {
    position: "absolute",
    // left: "40%",
    left: 0,
    right: 0,
    marginLeft: "auto",
    marginRight: "auto",
    minWidth: "350px",
    maxWidth: "fit-content",
    top: "110px",
  },
}));
