import { API_BASE_URL } from '../../apis/config';
import axios from 'axios';

/** Get Users */
export const browseUsers = async (authtoken, pageNo) => {
  return await axios
    .get(
      `${API_BASE_URL}/user?page=${pageNo}`, { headers: { "auth-token": authtoken } })
}

/** Suspend Users */
export const suspendUsers = async (authtoken, id) => {
  let data = ""
  return await axios
    .put(
      `${API_BASE_URL}/user/suspend/${id}`, data, { headers: { "auth-token": authtoken } })
}

/** Resume Users */
export const resumeUsers = async (authtoken, id) => {
  let data = ""
  return await axios
    .put(
      `${API_BASE_URL}/user/resume/${id}`, data,
      { headers: { "auth-token": authtoken } })
}

/** Delete Users */
export const deleteExistingUser = async (authtoken, id) => {
  return await axios.delete(`${API_BASE_URL}/user/${id}`, {
    headers: { "auth-token": authtoken },
  });
};

/** Add new Users */
export const addNewUser = async (authtoken, data) => {
  return await axios.post(`${API_BASE_URL}/user/`, data, {
    headers: { "auth-token": authtoken },
  });
};

/** Edit Users */
export const editSingleUser = async (authtoken, id, data) => {
  return await axios.put(`${API_BASE_URL}/user/${id}`, data, {
    headers: { "auth-token": authtoken },
  });
};

/** Reinvite Users */
export const reinviteSingleUser = async (authtoken, id, data) => {
  return await axios.put(`${API_BASE_URL}/user/reinvite/${id}`, data, {
    headers: { "auth-token": authtoken },
  });
};

/** Get Single User */
export const getSingleUser = async (authtoken, id) => {
  return await axios
    .get(
      `${API_BASE_URL}/user/${id}`, { headers: { "auth-token": authtoken } })
}

/** Get All Dealers */
export const getAllDealers = async authtoken => {
  return await axios.get(`${API_BASE_URL}/dealer/`, {
    headers: { "auth-token": authtoken },
  });
};

/** Activate Dealer */
export const activateUser = async (id, data) => {
  return await axios.put(`${API_BASE_URL}/user/activate/${id}`, data);
};
/** Reset password */
export const resetPassword = async (id, data) => {
  return await axios.put(`${API_BASE_URL}/user/reset-password/${id}`, data);
};