import React, { useEffect, useState } from "react";
import { Chip, TextField, Fade } from "@material-ui/core";
import "./Request.css";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import Paper from "@material-ui/core/Paper";
import { withStyles } from "@material-ui/core/styles";
import { Typography } from "../../components/Wrappers";
import IconButton from "@material-ui/core/IconButton";
import TableFooter from "@material-ui/core/TableFooter";
import Dialog from "@material-ui/core/Dialog";
import DialogTitle from "@material-ui/core/DialogTitle";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import Grid from "@material-ui/core/Grid";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Checkbox from "@material-ui/core/Checkbox";
import { FileCopy as FileCopyIcon } from "@material-ui/icons";
import Notification from "../../components/Notification";
import CloseIcon from "@material-ui/icons/Close";
import MenuItem from "@material-ui/core/MenuItem";
import FormControl from "@material-ui/core/FormControl";
import Select from "@material-ui/core/Select";
import { Button } from "../../components/Wrappers/Wrappers";
import classnames from "classnames";
// import {saveAs} from 'file-saver';
import {
  browseRequest,
  addNewRequest,
  revealKeycode,
  refuseKeycode,
  serveKeycode,
  cancelKeycode,
  lockKeycode,
  unLockKeycode,
} from "./RequestApi";
import {
  setGlobalSuccessMsg,
  useUserDispatch,
} from "../../context/UserContext";
import Backdrop from "@material-ui/core/Backdrop";
import CircularProgress from "@material-ui/core/CircularProgress";
import useStyles from "./styles";
import EthCrypto from "eth-crypto";
import CopyToClipboard from "react-copy-html-to-clipboard";
import WarningIcon from "@material-ui/icons/Warning";
import Pagination from "@material-ui/lab/Pagination";
import JsFileDownloader from "js-file-downloader";
import KeyCodePolicy from "../../images/KeyCodePolicy.pdf";
import Dropzone from "react-dropzone-uploader";
import "react-dropzone-uploader/dist/styles.css";
import { getDroppedOrSelectedFiles } from "html5-file-selector";
import GetAppIcon from "@material-ui/icons/GetApp";
import S3 from "aws-s3";

const config = {
  bucketName: "keycodesupportfiles",
  // dirName: 'photos', /* optional */
  region: "eu-central-1",
  accessKeyId: "AKIAYGH6RB6VTTDX3PII",
  secretAccessKey: "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",
};
const S3Client = new S3(config);

const generate_random_string = (string_length) => {
  let random_string = "";
  let random_ascii;
  let ascii_low = 65;
  let ascii_high = 90;
  for (let i = 0; i < string_length; i++) {
    random_ascii = Math.floor(
      Math.random() * (ascii_high - ascii_low) + ascii_low,
    );
    random_string += String.fromCharCode(random_ascii);
  }
  return random_string;
};

const StyledTableCell = withStyles((theme) => ({
  head: {
    backgroundColor: theme.palette.common.white,
  },
  body: {
    fontSize: 14,
  },
}))(TableCell);

const StyledTableRow = withStyles((theme) => ({
  root: {
    "&:nth-of-type(odd)": {
      backgroundColor: theme.palette.common.white,
    },
  },
}))(TableRow);

export default function Request(props) {
  var userDispatch = useUserDispatch();
  const classes = useStyles();
  const [page, setPage] = React.useState(1);
  const [filterPage, setFilterPage] = React.useState(1);
  const [open, setOpen] = React.useState(false);
  const [noOfPages, setNoOfPages] = useState();
  const [value, setValue] = React.useState("all");

  const [isFilterChanged, setIsFilterChanged] = React.useState(false);
  const [requestStatus, setRequestStatus] = useState({
    status: true,
    message: "",
  });
  const [open_one, setOpen_one] = React.useState(false);
  const [open_refuse, setOpen_refuse] = React.useState(false);
  const [open_cancel, setOpen_cancel] = React.useState(false);
  const [open_serve, setOpen_serve] = React.useState(false);
  const [imgSize, setImgSize] = React.useState(false);
  const [allRequestData, setAllRequestData] = useState([]);
  // const [imgarr] = useState([]);
  const [allFomrData, setallFomrData] = useState({
    vin: "",
    jobRequestNumber: "",
    urgent: false,
    underWarranty: false,
    // supportFiles: ""
    // supportFiles: "https://www.edarabia.com/wp-content/uploads/2018/02/all-you-need-know-about-emirates-id.jpg,https://i.pinimg.com/originals/60/77/c0/6077c016a19be1936b23e27f046445ab.jpg"
  });
  const [serveData, setServeData] = useState({
    ignition: "",
    pin: "",
    auth_pin: "",
    mechanical_code: "",
  });
  const [urgent, setUrgent] = React.useState(false);
  const [underWarranty, setUnderWarranty] = React.useState(false);
  const [accept, setAccept] = React.useState(false);
  const [validationsErr, setValidationsErr] = useState("");
  const [loaderToggle, setLoaderToggle] = useState(true);
  const [keyCodeLoaderToggle, setKeyCodeLoaderToggle] = useState(false);
  const [popupCancelId, setPopupCancelId] = useState("");
  const [refusedReason, setRefusedReason] = useState("");
  const [successMsg] = useState("");
  const [modalDetailsData, setModalDetailsData] = useState("");
  const [passwordValue, setPasswordValue] = useState("");
  const [userName] = useState("");
  const [keyCode, setKeyCode] = useState("");
  const [errorOfImg, setErrorOfImg] = useState("");
  const [revelbtnText, setRevelbtnText] = useState("cancel");
  const [keyCodeState, setKeyCodeState] = useState({ text: "", copied: false });
  const [imgData, setimgData] = useState([]);
  const authtoken = localStorage.getItem("jwtauthtoken");
  let userdetails = JSON.parse(localStorage.getItem("userdetails"));
  let userrole = userdetails.role;
  let userId = userdetails._id;

  const itemsPerPage = 10;

  let statusSlug = {
    new: "New",
    revealed: "Revealed",
    locked: "Locked",
    served: "Served",
    refused: "Refused",
    canceled: "Canceled",
  };

  let statusActionSlug = {
    new: "Cancel",
    refused: "Get Reason",
    served: "Reveal",
    locked: "refuse serve",
  };

  /** Browse Request Data  API*/
  const borwsRequestDataApi = (DataVal, pageNo) => {
    setLoaderToggle(true);
    const browserequestData = browseRequest(
      localStorage.getItem("jwtauthtoken"),
      DataVal,
      pageNo,
    );
    browserequestData
      .then((response) => {
        setLoaderToggle(false);
        setAllRequestData(response.data.data);
        setRequestStatus({
          ...requestStatus,
          status: response.data.success,
          message: "",
        });
        response.data.pagination &&
          setNoOfPages(
            Math.ceil(response.data.pagination.response.total / itemsPerPage),
          );
      })
      .catch((error) => {
        setAllRequestData([]);
        error.response.data &&
          setRequestStatus({
            ...requestStatus,
            status: error.response.data.success,
            message: error.response.data.message,
          });
        setLoaderToggle(false);
      });
  };
  useEffect(() => {
    borwsRequestDataApi("", page);
    setIsFilterChanged(false);
  }, [page]);
  /** Browse Request API*/
  const borwsRequestApi = (DataVal, pageNo) => {
    setLoaderToggle(true);
    const browserequestData = browseRequest(authtoken, DataVal, pageNo);
    browserequestData
      .then((response) => {
        setLoaderToggle(false);
        setAllRequestData(response.data.data);
        response.data.pagination &&
          setNoOfPages(
            Math.ceil(response.data.pagination.response.total / itemsPerPage),
          );
        setRequestStatus({
          ...requestStatus,
          status: response.data.success,
          message: "",
        });
      })
      .catch((error) => {
        setAllRequestData([]);
        error.response.data &&
          setRequestStatus({
            ...requestStatus,
            status: error.response.data.success,
            message: error.response.data.message,
          });
        setLoaderToggle(false);
      });
  };
  /** Close Serve MOdal*/
  const handleClose_serve = () => {
    setOpen_serve(false);
  };
  /**cancel MOdal*/
  const handleCancel = (data) => {
    setOpen_cancel(true);
    setPopupCancelId(data._id);
  };
  /** Close Refuse MOdal*/
  const handleClose_refuse = () => {
    setOpen_refuse(false);
  };
  /** Open Refuse MOdal*/
  const handleOpen_refuse = (data) => {
    setOpen_refuse(true);
    setPopupCancelId(data._id);
    setModalDetailsData(data);
    setValidationsErr({ errors: [] });
  };
  /** Close Reveal MOdal*/
  const handleClose_one = () => {
    setOpen_one(false);
  };
  /** Open New Request Modal*/
  const handleClickOpen = () => {
    setallFomrData({
      ...allFomrData,
      vin: "",
      jobRequestNumber: "",
      urgent: false,
      underWarranty: false,
      // supportFiles:""
    });
    setOpen(true);
    setValidationsErr({ errors: [] });
  };

  const handleClose = () => {
    setOpen(false);
    setUrgent(false);
    setUnderWarranty(false);
    setallFomrData({
      ...allFomrData,
      vin: "",
      jobRequestNumber: "",
      urgent: false,
      underWarranty: false,
      // supportFiles:""
    });
  };
  /** Close Refuse Modal*/
  const handleChange = (event) => {
    setIsFilterChanged(event.target.value === "all" ? false : true);
    setValue(event.target.value);
    event.target.value === "all" && borwsRequestDataApi("", 1);
    setFilterPage(1);
    event.target.value !== "all" &&
      borwsRequestApi(
        event.target.value === "all" ? "" : event.target.value,
        filterPage,
      );
  };
  /** handleChange form*/
  const handleChangeAdd = (event) => {
    setallFomrData({
      ...allFomrData,
      [event.target.name]:
        event.target.value !== "" ? event.target.value : null,
    });
  };
  /** handleChange checkUrgent*/
  const handleChangecheckUrgent = (event) => {
    setUrgent(event.target.checked);
    setallFomrData({ ...allFomrData, urgent: event.target.checked });
  };
  /** Close Refuse Modal*/
  const handleChangecheckUnderWarranty = (event) => {
    setUnderWarranty(event.target.checked);
    setallFomrData({ ...allFomrData, underWarranty: event.target.checked });
  };
  const handleaccept = (e) => {
    setAccept(e.target.checked);
  }
  /**Validation of Form*/
  const handleValidation = () => {
    let fields = allFomrData;
    let errors = {};
    let formIsValid = true;

    if (!fields["vin"]) {
      formIsValid = false;
      errors["vin"] = "VIN can not be empty";
    }
    if(accept === false){
      alert("hey")
    }
    setValidationsErr({ errors: errors });
    return formIsValid;
  };
  /**Validation of Password*/
  const handlePasswordValidation = () => {
    let errors = {};
    let formIsValid = true;

    if (passwordValue === "") {
      formIsValid = false;
      errors["password"] = "Password can not be empty";
    }
    setValidationsErr({ errors: errors });
    return formIsValid;
  };
  /**Validation of Refuse form*/
  const handleRefuseValidation = () => {
    let errors = {};
    let formIsValid = true;
    if (refusedReason === "") {
      formIsValid = false;
      errors["reason"] = "Reason can not be empty";
    } else {
      formIsValid = true;
    }
    setValidationsErr({ errors: errors });
    return formIsValid;
  };
  /**Validation of Reason form*/
  const handleChangeReason = (e) => {
    setRefusedReason(e.target.value);
  };

  /**Add New Request form*/
  const handleOnsubmit = () => {
    if (handleValidation()) {
      let imgarr = [];
      if (imgSize === true) {
        setErrorOfImg("Sorry, There was an error uploading your File.");
      } else
      if (imgData && imgData.length > 0) {
        imgData.map((item, index) => {
          if (item.meta.status === "done") {
            const newFileName =
              generate_random_string(4) +
              item.file.name.split(".").slice(0, -1).join(".");
            S3Client.uploadFile(item.file, newFileName)
              .then((data) => {
                imgarr.push(data.location);
                if (imgarr.length === imgData.length) {
                  let supportedfiles = imgarr.join(",");
                  allFomrData.supportFiles = supportedfiles;
                  const addRequest = addNewRequest(authtoken, allFomrData);
                  addRequest
                    .then((response) => {
                      setallFomrData(response);
                      setGlobalSuccessMsg(
                        response.data.message,
                        userDispatch,
                        "success",
                      );
                      response.data.success && borwsRequestApi("");
                      setallFomrData({
                        ...allFomrData,
                        vin: "",
                        jobRequestNumber: "",
                        urgent: false,
                        underWarranty: false,
                        supportFiles: "",
                      });
                      setOpen(false);
                    })
                    .catch((error) => {
                      let errors = {};
                      error.response.data.errors &&
                        error.response.data.errors.map((item) => {
                          return (errors[item.param] = item.msg);
                        });
                      setValidationsErr({ errors: errors });
                    });
                }
              })
              .catch((err) => {});
          } else {
            setErrorOfImg("Sorry, There was an error uploading your File.");
          }
        });
      } 
      // else 
      // {
      //   if (imgSize === true) {
      //     setErrorOfImg("Sorry, There was an error uploading your File.");
      //   } 
        else {
          setErrorOfImg("");
          const addRequest = addNewRequest(authtoken, allFomrData);
          addRequest
            .then((response) => {
              setallFomrData(response);
              setGlobalSuccessMsg(
                response.data.message,
                userDispatch,
                "success",
              );
              response.data.success && borwsRequestApi("");
              setallFomrData({
                ...allFomrData,
                vin: "",
                jobRequestNumber: "",
                urgent: false,
                underWarranty: false,
                supportFiles: "",
              });
              setOpen(false);
            })
            .catch((error) => {
              let errors = {};
              error.response.data.errors &&
                error.response.data.errors.map((item) => {
                  return (errors[item.param] = item.msg);
                });
              setValidationsErr({ errors: errors });
            });
        }
      // }
    } else {
      setOpen(true);
      setErrorOfImg("");
    }
  };

  // --------reveal API-----------//
  const handleRevealKey = async (data) => {
    setRevelbtnText("Cancel");
    let errors = {};
    errors["badmac"] = "";

    setValidationsErr({ errors: errors });
    if (handlePasswordValidation()) {
      try {
        let errors = {};
        errors["badmac"] = "";

        setValidationsErr({ errors: errors });
        setKeyCodeLoaderToggle(true);
        setPasswordValue("");
        const hashedPasswords = EthCrypto.hash.keccak256(passwordValue);
        const secret = hashedPasswords + hashedPasswords;
        const { privateKey } = EthCrypto.createIdentity(
          Buffer.from(secret, "utf-8"),
        );
        let keyCodeData = null;
        keyCodeData = await EthCrypto.decryptWithPrivateKey(
          privateKey,
          JSON.parse(data.cipher),
        );
        setKeyCodeState({ ...keyCodeState, text: keyCodeData });
        const revealKey = revealKeycode(authtoken, data._id);
        revealKey
          .then((response) => {
            setKeyCode(JSON.parse(keyCodeData));
            setKeyCodeLoaderToggle(false);
            setRevelbtnText("Close");
          })
          .catch((error) => {
            let errors = {};
            error.response.data &&
              (errors["password"] = error.response.data.message);
            setValidationsErr({ errors: errors });
            setOpen_one(true);
            setKeyCodeLoaderToggle(false);
          });
      } catch (e) {
        setKeyCodeLoaderToggle(false);
        let errors = {};
        errors["badmac"] = e.message;

        setValidationsErr({ errors: errors });
      }
    } else {
      setOpen_one(true);
      setKeyCodeLoaderToggle(false);
    }
  };

  //  -------- refused API----------//
  const handleRefuseKey = (id) => {
    if (handleRefuseValidation()) {
      const data = { reason: refusedReason };
      const refusedKey = refuseKeycode(authtoken, data, id);
      refusedKey
        .then((response) => {
          setGlobalSuccessMsg(response.data.message, userDispatch, "success");
          borwsRequestApi(value, filterPage);
          setOpen_refuse(false);
        })
        .catch((error) => {
          setOpen_refuse(false);
          error.response &&
            setGlobalSuccessMsg(
              error.response.data.message,
              userDispatch,
              "error",
            );
        });
    } else {
      setOpen_refuse(true);
    }
  };

  //-------serve API---------//

  const handleChangeServe = (event) => {
    setServeData({ ...serveData, [event.target.name]: event.target.value });
  };

  const handleServeKey = async (data) => {
    const encrypted = await EthCrypto.encryptWithPublicKey(
      data.userPublicKey, // encrypt with alice's publicKey
      JSON.stringify(serveData),
    );

    let test = JSON.stringify(encrypted);
    const payloadData = { cipher: test };

    const servedKey = serveKeycode(authtoken, data._id, payloadData);
    servedKey
      .then((response) => {
        setGlobalSuccessMsg(response.data.message, userDispatch, "success");
      })
      .catch((error) => {
        let errors = {};
        error.response.data.errors &&
          error.response.data.errors.map((item) => {
            return (errors[item.param] = item.msg);
          });
        errors && Object.entries(errors).length > 0
          ? setValidationsErr({ errors: errors })
          : error.response &&
            setGlobalSuccessMsg(
              error.response.data.message,
              userDispatch,
              "error",
            );
      });
  };

  // --------cancel API ------- //
  const handleCancelKey = (id) => {
    const cancelKey = cancelKeycode(authtoken, id);
    cancelKey
      .then((response) => {
        setOpen_cancel(false);
        setGlobalSuccessMsg(response.data.message, userDispatch, "success");
        borwsRequestApi("");
      })
      .catch((error) => {
        error.response &&
          setGlobalSuccessMsg(
            error.response.data.message,
            userDispatch,
            "error",
          );
      });
  };

  const saveFile = () => {
    // fileSaver.saveAs("http://www.africau.edu/images/default/sample.pdf","file.pdf");
    // fileDownload("http://www.africau.edu/images/default/sample.pdf","my.pdf");
    const fileUrl = KeyCodePolicy;

    new JsFileDownloader({
      url: fileUrl,
    })
      .then(function () {
        // Called when download ended
      })
      .catch(function (error) {
        // Called when an error occurred
      });
  };

  const downloadImg = (items) => {
    const fileUrl = items;
    new JsFileDownloader({
      url: fileUrl,
    })
      .then(function () {
        // Called when download ended
      })
      .catch(function (error) {
        // Called when an error occurred
      });
  };
  /**Validation of Reason form*/
  const handleLock = (id) => {
    const lockRequest = lockKeycode(authtoken, id);
    lockRequest
      .then((response) => {
        if (response.data.success) {
          // setSuccessMsg(response.data.message)
          // borwsRequestApi("")
        }
        // setGlobalSuccessMsg(response.data.message, userDispatch, "success")
      })
      .catch((error) => {
        // error.response && setGlobalSuccessMsg(error.response.data.errors[0].msg, userDispatch, "error")
      });
    // setalertStatus("Lock a corporate");
  };

  /**Lock API*/
  const handleUnLock = (id) => {
    const lockRequest = unLockKeycode(authtoken, id);
    lockRequest
      .then((response) => {
        if (response.data.success) {
          // setSuccessMsg(response.data.message)
          // borwsRequestApi("")
        }
        // setGlobalSuccessMsg(response.data.message, userDispatch, "success")
      })
      .catch((error) => {
        // error.response && setGlobalSuccessMsg(error.response.data.errors[0].msg, userDispatch, "error")
      });
    // setalertStatus("Lock a corporate");
  };

  /** close Serve Model*/
  const handleServeCancel = (data) => {
    setOpen_serve(false);
    setValidationsErr({ errors: [] });
    handleUnLock(data._id);
  };
  /** Open Serve Model*/
  const handleServe = (data, id) => {
    setOpen_serve(true);
    setModalDetailsData(data);
    setValidationsErr({ errors: [] });
    handleLock(data._id);
  };
  /** Open Reveal Model*/
  const handleOpen_reveal = (data, id) => {
    setOpen_one(true);
    setModalDetailsData(data);
    setValidationsErr({ errors: [] });
  };
  /** Close KeyCode Model*/
  const onCancelKeyCodeModal = () => {
    setKeyCodeState({ ...keyCodeState, text: "", copied: false });
    setOpen_one(false);
    setKeyCodeLoaderToggle(false);
    borwsRequestApi("");
  };

  const handleUserArr = (value) => {
    let reqArr = [];
    noOfPages !== undefined &&
      allRequestData
        .slice((value - 1) * itemsPerPage, value * itemsPerPage)
        .map((item, index) => {
          return reqArr.push(item._id);
        });
  };
  /** Open Serve Model*/
  const handlePaginationChange = (event, pageNo) => {
    isFilterChanged ? setFilterPage(pageNo) : setPage(pageNo);
    handleUserArr(pageNo);
    isFilterChanged && borwsRequestApi(value, pageNo);
  };

  const handleChangeStatus = ({ meta }, status, files, allFiles) => {
    let statusDetails = files.map((f)=>f.meta.status)
    if(statusDetails.includes("done")){
      setImgSize(false);
    }
    else if(statusDetails.includes("error_file_size") || statusDetails.includes("aborted") || statusDetails.includes("uploading")){
      setImgSize(true);
    }
    else if(statusDetails.includes("removed")){
      setImgSize(false);
    }
    if (status === "done") {
      setimgData(files.map((f) => f));
      setErrorOfImg("");
    }
    if (status === "removed") {
      if (imgData && imgData.length > 0) {
        let imgdatas = imgData;
        let new_data = imgdatas.filter((data) => data.meta.name !== meta.name);
        setimgData(new_data);
      }
    }
   
  };
  
  const Input = ({ accept, onFiles, files, getFilesFromEvent }) => {
    const text = files.length > 0 ? "ADD IMAGE" : "BROWSE FILE";
    const classes = useStyles();
    return (
      <>
        {files.length <= 0 && (
          <div>
            <p className={classes.dragTextP}>Drag and drop photos here</p>
            <p className={classes.text_or}>or</p>
          </div>
        )}
        <label className={classes.uploadImageButton}>
          {text}
          <input
            style={{ display: "none" }}
            type="file"
            accept={accept}
            multiple
            onChange={(e) => {
              getFilesFromEvent(e).then((chosenFiles) => {
                onFiles(chosenFiles);
              });
            }}
          />
        </label>
      </>
    );
  };
  const getFilesFromEvent = (e) => {
    return new Promise((resolve) => {
      getDroppedOrSelectedFiles(e).then((chosenFiles) => {
        resolve(chosenFiles.map((f) => f.fileObject));
      });
    });
  };

  const handleImageEvent = (data) => {
    window.open(data, "_blank");
  };
  return (
    <div className="users_class">
      <Grid
        container
        direction="row"
        justify="flex-end"
        alignItems="center"
        className="filterContainer"
      >
        <FormControl className={classes.formControl}>
          <Select
            labelId="demo-simple-select-label"
            id="demo-simple-select"
            value={value}
            onChange={handleChange}
          >
            <MenuItem value="all">
              <em>All</em>
            </MenuItem>
            <MenuItem value={"new"}>New</MenuItem>
            <MenuItem value={"served"}>Served</MenuItem>
            <MenuItem value={"canceled"}>Canceled</MenuItem>
            <MenuItem value={"refused"}>Refused</MenuItem>
            <MenuItem value={"revealed"}>Revealed</MenuItem>
          </Select>
        </FormControl>

        {userrole === "dealer" ? (
          <Button
            variant="contained"
            size="medium"
            color="success"
            onClick={handleClickOpen}
          >
            New Request
          </Button>
        ) : null}
      </Grid>

      <>
        <Dialog
          // style={{ padding: "10px" }}
          fullWidth={true}
          open={open}
          onClose={handleClose}
          classes={{ paperFullWidth: classes.dialog_width }}
          aria-labelledby="responsive-dialog-title"
        >
          <div class="action-dialog_top">
            <DialogTitle
              id="alert-dialog-slide-title"
              className="action_dialog_title text-center"
            >
              Add new keycode request
            </DialogTitle>
            <div class="action-dialog_icon" onClick={() => handleClose()}>
              <IconButton aria-label="close" className={classes.closeButton}>
                <CloseIcon />
              </IconButton>
            </div>
          </div>

          <DialogContent style={{ overflowY: "unset" }} className="user_form">
            <Grid container spacing={6}>
              <Grid
                className="user_label"
                container
                item
                xs={12}
                spacing={12}
                sm={3}
              >
                <label className="comman_label">VIN <sup className="textRed">*</sup> :</label>
              </Grid>
              <Grid
                className="user_label"
                container
                item
                xs={12}
                spacing={12}
                sm={9}
              >
                <input
                  type="text"
                  className="input_type"
                  placeholder="VIN"
                  name="vin"
                  onChange={handleChangeAdd}
                ></input>

                {validationsErr && validationsErr.errors.vin && (
                  <div class="errMsgConatiner">{validationsErr.errors.vin}</div>
                )}
              </Grid>

              <Grid
                className="user_label"
                container
                item
                xs={12}
                spacing={12}
                sm={3}
              >
                <label className="comman_label">JOB#</label>
              </Grid>
              <Grid
                className="user_label"
                container
                item
                xs={12}
                spacing={12}
                sm={9}
              >
                <input
                  type="text"
                  className="input_type"
                  placeholder="Job Number"
                  name="jobRequestNumber"
                  onChange={handleChangeAdd}
                ></input>
                {validationsErr && validationsErr.errors.jobRequestNumber && (
                  <div class="errMsgConatiner">
                    {validationsErr.errors.jobRequestNumber}
                  </div>
                )}
              </Grid>

              <Grid
                className="user_label"
                container
                item
                xs={12}
                spacing={12}
                sm={3}
              >
                <label className="comman_label">SUPPORTED DOCUMENTS:</label>
              </Grid>
              <Grid container item xs={12} spacing={12} sm={9}>
                <Dropzone
                  getUploadParams={() => ({ url: "https://httpbin.org/post" })}
                  getFilesFromEvent={getFilesFromEvent}
                  onChangeStatus={handleChangeStatus}
                  canRemove={true}
                  InputComponent={Input}
                  maxSizeBytes={2000000}
                  accept="image/*"
                  maxFiles={8}
                  styles={{
                    dropzone: {
                      height: 100,
                      overflow: "auto",
                      border: "0.5px solid #727272",
                      borderRadius: "1px",
                    },
                    inputLabel: { color: "#757575" },
                  }}
                />
                {errorOfImg && (
                  <div className="errMsgConatiner">{errorOfImg}</div>
                )}
              </Grid>

              <Grid
                className="req_checkbox"
                container
                item
                xs={12}
                spacing={12}
                sm={12}
              >
                <FormControlLabel
                  control={
                    <Checkbox
                      checked={urgent}
                      name="urgent"
                      onChange={handleChangecheckUrgent}
                      color="primary"
                    />
                  }
                  label="Urgent"
                  labelPlacement="end"
                  value={urgent}
                  className="check_box"
                />
              </Grid>

              <Grid
                className="req_checkbox"
                container
                item
                xs={12}
                spacing={12}
                sm={12}
              >
                <FormControlLabel
                  control={
                    <Checkbox
                      checked={underWarranty}
                      onChange={handleChangecheckUnderWarranty}
                      name="underWarranty"
                      color="primary"
                    />
                  }
                  label="Under Warranty"
                  labelPlacement="end"
                  value={underWarranty}
                  className="check_box"
                />
              </Grid>

              <Grid
                className="req_checkbox mb-20"
                container
                item
                xs={12}
                spacing={12}
                sm={12}
              >
               <div className="pdfAcceptance">
               <FormControlLabel
                  control={
                    <Checkbox
                      checked={accept}
                      onChange={handleaccept}
                      name="accept"
                      color="primary"
                    />
                  }
                  label=""
                  labelPlacement="end"
                  value={accept}
                  className="check_box mr-0"
                />
              
                  <div>I agree and acknowledge that I have read the Dealer Policy regarding the key code (a copy of which is available here:
                  <span className={classes.pdf} onClick={saveFile}>Keycode Policy.pdf </span>
                  ) and I am acting in compliance with the policy.</div>
               </div>
              </Grid>
            </Grid>
          </DialogContent>

          <DialogActions>
            {accept ? 
            <Button
              onClick={handleOnsubmit}
              variant="contained"
              size="medium"
              color="primary"
            >
              Add Request
            </Button>: <Button
              variant="contained"
              size="medium"
              color="primary"
              disabled
            >
              Add Request
            </Button>}
            <Button
              onClick={handleClose}
              variant="contained"
              size="medium"
              color="secondary"
            >
              Cancel
            </Button>
          </DialogActions>
        </Dialog>
      </>

      {successMsg !== "" && (
        <Grid
          container
          className={classes.successMsgContainer}
          item
          xs={12}
          spacing={12}
          sm={12}
        >
          <Notification
            className={`successMsgContainerInner ${classes.notificationItem}`}
            type="message"
            variant="contained"
            shadowless
            message={successMsg}
            color="success"
          />
        </Grid>
      )}

      <TableContainer component={Paper}>
        <Table className={classes.table} aria-label="customized table">
          <TableHead>
            <TableRow>
              <StyledTableCell align="center">VIN</StyledTableCell>
              <StyledTableCell align="center">Job #</StyledTableCell>
              <StyledTableCell align="center">Under Warranty</StyledTableCell>
              <StyledTableCell align="center">Urgent</StyledTableCell>
              <StyledTableCell align="center">Req.At</StyledTableCell>
              <StyledTableCell align="center">Served By</StyledTableCell>
              <StyledTableCell align="center">Dealer</StyledTableCell>
              <StyledTableCell align="center">Requested By</StyledTableCell>
              <StyledTableCell align="center">Status</StyledTableCell>
              <StyledTableCell align="center">Actions</StyledTableCell>
              <StyledTableCell />
            </TableRow>
          </TableHead>
          <TableBody>
            {allRequestData.length === 0 && (
              <StyledTableRow>
                <TableCell colSpan="12" align="center">
                  No Records Found
                </TableCell>
              </StyledTableRow>
            )}
            {requestStatus["status"] === true ? (
              noOfPages !== undefined &&
              allRequestData !== undefined &&
              allRequestData.length > 0 &&
              allRequestData
                .slice(0, itemsPerPage)
                .map((allRequestData, key1) => (
                  <StyledTableRow key={key1}>
                    <StyledTableCell align="center">
                      {allRequestData.vin}
                    </StyledTableCell>
                    <StyledTableCell align="center">
                      {allRequestData.jobRequestNumber}
                    </StyledTableCell>
                    <StyledTableCell align="center">
                      {allRequestData.underWarranty ? "True" : "False"}
                    </StyledTableCell>
                    <StyledTableCell align="center">
                      {allRequestData.urgent ? "True" : "False"}
                    </StyledTableCell>
                    <StyledTableCell align="center">
                      {allRequestData.createdAt}
                    </StyledTableCell>
                    <StyledTableCell align="center">
                      {allRequestData.servedBy.length > 0 &&
                        allRequestData.servedBy[0].name}
                    </StyledTableCell>
                    <StyledTableCell align="center">
                      {allRequestData.dealer.length > 0 &&
                        allRequestData.dealer[0].name}
                    </StyledTableCell>
                    <StyledTableCell align="center">
                      {allRequestData.requestedBy.length > 0 &&
                        allRequestData.requestedBy[0].name}
                    </StyledTableCell>
                    <StyledTableCell align="center" className="status">
                      {statusSlug[allRequestData.status] !== "" &&
                        statusSlug[allRequestData.status] !== undefined && (
                          <Chip
                            className={classes.chipPosittion}
                            label={statusSlug[allRequestData.status]}
                            classes={{
                              root:
                                classes[
                                  allRequestData.status === "new"
                                    ? "success"
                                    : allRequestData.status === "revealed"
                                    ? "success"
                                    : allRequestData.status === "locked"
                                    ? "info"
                                    : allRequestData.status === "refused" ||
                                      allRequestData.status === "canceled"
                                    ? "secondary"
                                    : "primary"
                                ],
                            }}
                          />
                        )}
                    </StyledTableCell>

                    <StyledTableCell align="center">
                      <div className={classes.buttonsContainer}>
                        {allRequestData.status === "new" &&
                          userrole === "dealer" && (
                            <Button
                              variant="contained"
                              color="secondary"
                              onClick={() => handleCancel(allRequestData)}
                              className={classnames(
                                classes.notificationCallButton,
                              )}
                            >
                              {statusActionSlug[allRequestData.status]}
                            </Button>
                          )}

                        {allRequestData.status === "new" &&
                          userrole === "corporate" && (
                            <>
                              <Button
                                variant="contained"
                                onClick={() =>
                                  handleOpen_refuse(allRequestData)
                                }
                                color="secondary"
                                style={{ marginRight: "10px" }}
                                className={classnames(
                                  classes.notificationCallButton,
                                )}
                              >
                                Refuse
                              </Button>
                              {/* <Button
                              variant="contained" onClick={() => handleLock(allRequestData._id)}
                              color="info"
                              className={classnames(classes.notificationCallButton)}
                            >
                              Lock
                            </Button> */}
                              <Button
                                variant="contained"
                                onClick={() => handleServe(allRequestData)}
                                color="primary"
                                className={classnames(
                                  classes.notificationCallButton,
                                )}
                              >
                                Serve
                              </Button>
                            </>
                          )}

                        {/* {(allRequestData.status === 'refused' && userrole === 'corporate' && */}
                        {allRequestData.status === "refused" && (
                          <>
                            <Typography component="a" color="primary">
                              {allRequestData.comment}
                            </Typography>
                          </>
                        )}

                        {allRequestData.status === "locked" &&
                          userrole === "corporate" &&
                          allRequestData.lockedBy === userId && (
                            <>
                              <Button
                                variant="contained"
                                onClick={() =>
                                  handleOpen_refuse(allRequestData)
                                }
                                color="secondary"
                                style={{ marginRight: "10px" }}
                                className={classnames(
                                  classes.notificationCallButton,
                                )}
                              >
                                Refuse
                              </Button>
                              <Button
                                variant="contained"
                                onClick={() => handleServe(allRequestData)}
                                color="primary"
                                className={classnames(
                                  classes.notificationCallButton,
                                )}
                              >
                                Serve
                              </Button>
                            </>
                          )}
                        {allRequestData.status === "served" &&
                          userrole === "dealer" &&
                          allRequestData.requestedBy[0]._id === userId && (
                            <Button
                              variant="contained"
                              onClick={() => handleOpen_reveal(allRequestData)}
                              color="success"
                              className={classnames(
                                classes.notificationCallButton,
                              )}
                            >
                              {statusActionSlug[allRequestData.status]}
                            </Button>
                          )}
                      </div>
                    </StyledTableCell>
                  </StyledTableRow>
                ))
            ) : (
              <StyledTableRow>
                <TableCell colSpan="12" align="center">
                  <Typography color="secondary">
                    {" "}
                    {requestStatus["message"]}
                  </Typography>
                </TableCell>
              </StyledTableRow>
            )}
          </TableBody>
        </Table>
        <Table className={classes.table} aria-label="customized table">
          {allRequestData.length > 0 && (
            <TableFooter style={{ float: "right" }}>
              {!loaderToggle && (
                <Grid item xs={12} className={classes.notificationPagination}>
                  {noOfPages !== null && noOfPages !== undefined && (
                    <Pagination
                      count={noOfPages}
                      page={isFilterChanged ? filterPage : page}
                      onChange={handlePaginationChange}
                      defaultPage={1}
                      color="primary"
                      size="large"
                    />
                  )}
                </Grid>
              )}
            </TableFooter>
          )}
        </Table>
      </TableContainer>

      <>
        <Dialog
          style={{ padding: "10px" }}
          fullWidth={true}
          open={open_one}
          onClose={handleClose_one}
          className={classes.dialog_padding}
          maxWidth="sm"
          aria-labelledby="responsive-dialog-title"
        >
          <div class="action-dialog_top">
            <DialogTitle
              id="alert-dialog-slide-title"
              class="action_dialog_title text-center"
            >
              Reveal keycode
            </DialogTitle>
            <div class="action-dialog_icon" onClick={() => handleClose_one()}>
              <IconButton aria-label="close" className={classes.closeButton}>
                <CloseIcon />
              </IconButton>
            </div>
          </div>

          <DialogContent className="user_form">
            <Grid className="req_action_form" container spacing={6}>
              <Grid container className="req_action_form" item xs={12} sm={6}>
                <div>
                  <div>VIN: {modalDetailsData.vin}</div>
                  <div>
                    Urgent: {modalDetailsData.urgent ? "true" : "false"}
                  </div>
                  <div>Requested: {modalDetailsData.updatedAt}</div>
                </div>
              </Grid>
              <Grid className="req_action_form" container item xs={12} sm={6}>
                <div className="right_data">
                  <div>Job#: {modalDetailsData.jobRequestNumber}</div>
                  <div>
                    Under Warranty:{" "}
                    {modalDetailsData.underWarranty ? "true" : "false"}
                  </div>
                  <div>Served by: {userName[modalDetailsData.servedBy]}</div>
                </div>
              </Grid>

              {modalDetailsData.supportFiles && (
                <Grid
                  className="req_action_form img_margin"
                  container
                  item xs={12} sm={12}
                >
                <div className="marginAuto">
                <div className="text-center">
                  <h2 className="supported_documents">SUPPORTED DOCUMENTS</h2>
                </div>
                    <div className="image-row">
                      <div className="images">
                        {modalDetailsData.supportFiles
                          .split(",")
                          .map((item) => (
                            <div className="img-download">
                              <img
                                className="img-hw"
                                src={item}
                                onClick={() => handleImageEvent(item)}
                                alt="download"
                              />
                              <div
                                className="img-blur"
                                onClick={() => downloadImg(item)}
                              >
                                <GetAppIcon fontSize="small" />
                              </div>
                            </div>
                          ))}
                      </div>
                    </div>
                  </div>
                </Grid>
              )}

              <Grid className="req_action_form" container item xs={12} sm={12}>
                <Typography
                  variant="h8"
                  weight="bold"
                  className={classes.imgMargin}
                >
                  Password
                </Typography>
              </Grid>

              <Grid className="req_action_form" container item xs={9} sm={9}>
                <TextField
                  id="password"
                  InputProps={{
                    classes: {
                      input: classes.textField,
                    },
                  }}
                  value={passwordValue}
                  onChange={(e) => setPasswordValue(e.target.value)}
                  margin="normal"
                  name="password"
                  placeholder="Password"
                  type="password"
                  fullWidth
                />
              </Grid>

              <DialogActions>
                <Button
                  onClick={() => {
                    handleRevealKey(modalDetailsData);
                  }}
                  variant="contained"
                  size="medium"
                  color="primary"
                >
                  Ok
                </Button>
              </DialogActions>
              <Grid>
                {validationsErr && validationsErr.errors.badmac && (
                  <div class="errMsgConatiner">
                    {validationsErr.errors.badmac}
                  </div>
                )}
              </Grid>
              <Grid className="req_action_form" container item xs={12} sm={12}>
                <Fade in={validationsErr && validationsErr.errors.password}>
                  <Typography
                    color="secondary"
                    className={classes.errorMessage}
                  >
                    {validationsErr && validationsErr.errors.password}
                  </Typography>
                </Fade>
              </Grid>
              {/* <Grid container spacing={12}>
                <Grid container item xs={12} sm={12} className={classes.margin}>
                  <span className="req_padding">
                    To View Our Terms And Condition.
                  </span>
                  <a className={classes.pdf} onClick={saveFile}>
                    Click here
                  </a>
                </Grid>
              </Grid> */}
              <Grid className="req_action_form" container item xs={12} sm={12}>
                <span className="req_padding">
                  Keycode is incepted and it needs your password decrepit it.
                </span>
              </Grid>

              <div className={classes.loaderDiv}>
                {/* <Loader /> */}
                {keyCodeLoaderToggle ? (
                  <CircularProgress color="inherit" />
                ) : null}
              </div>
              {keyCode !== undefined &&
                keyCode !== null &&
                Object.entries(keyCode).length > 0 && (
                  <>
                    <Grid
                      className="req_action_form"
                      container
                      item
                      xs={12}
                      sm={12}
                    >
                      <div className="keycode_box">
                        <strong className="key_code">Keycode:</strong>
                        <span
                          className={`${
                            keyCodeState.copied ? "key_copied" : "key_copy"
                          }`}
                        >
                          <CopyToClipboard
                            options={{ asHtml: true }}
                            text={`<span>
                            Ignition: ${keyCode.ignition}<br/>
                            PIN: ${keyCode.pin}<br/>
                            Auth Pin:   ${keyCode.auth_pin}<br/>
                            Mechanical Code:   ${keyCode.mechanical_code}<br/>
                            </span>`}
                            onCopy={() =>
                              setKeyCodeState({ ...keyCodeState, copied: true })
                            }
                          >
                            <FileCopyIcon />
                          </CopyToClipboard>
                        </span>
                        <span className="list_key">
                          Ignition: {keyCode.ignition}
                          <br />
                          PIN: {keyCode.pin}
                          <br />
                          Auth PIN: {keyCode.auth_pin}
                          <br />
                          Mechanical Code: {keyCode.mechanical_code}
                          <br />
                        </span>
                      </div>
                    </Grid>

                    <Grid
                      className="req_action_form"
                      container
                      item
                      xs={12}
                      sm={12}
                    >
                      <span className="req_note">
                        Note that you can see the keycode one time only.After
                        you click close, the status will be changed to reveal it
                        again.Please copy the above code and keep it in a safe
                        location,then delete when you finish.
                      </span>
                    </Grid>
                  </>
                )}
            </Grid>
          </DialogContent>

          <DialogActions>
            <Button
              onClick={() => {
                onCancelKeyCodeModal();
              }}
              variant="contained"
              size="medium"
              color="secondary"
            >
              {revelbtnText}
            </Button>
          </DialogActions>
        </Dialog>
      </>

      {/*----------------------- popup  of  refused  -----------------------------  */}

      <div>
        {userrole === "corporate" ? (
          <Dialog
            style={{ padding: "10px" }}
            fullWidth={true}
            open={open_refuse}
            onClose={handleClose_refuse}
            className={classes.dialog_padding}
            maxWidth="sm"
            aria-labelledby="responsive-dialog-title"
          >
            <div class="action-dialog_top">
              <DialogTitle
                id="alert-dialog-slide-title"
                class="action_dialog_title text-center"
              >
                Refuse keycode request
              </DialogTitle>
              <div
                class="action-dialog_icon"
                onClick={() => handleClose_refuse()}
              >
                <IconButton aria-label="close" className={classes.closeButton}>
                  <CloseIcon />
                </IconButton>
              </div>
            </div>

            <DialogContent className="user_form">
              <Grid className="req_action_form" container spacing={6}>
                <Grid
                  container
                  className="req_action_form"
                  item
                  xs={12}
                  spacing={12}
                  sm={6}
                >
                  <div className="req_padding">
                    <div>VIN: {modalDetailsData.vin}</div>
                    <div>
                      Urgent: {modalDetailsData.urgent ? "true" : "false"}.
                    </div>
                    <div>Requested: {modalDetailsData.updatedAt}</div>
                  </div>
                </Grid>
                <Grid
                  className="req_action_form"
                  container
                  item
                  xs={12}
                  spacing={12}
                  sm={6}
                >
                  <div className="right_data">
                    <div>Job#: {modalDetailsData.jobRequestNumber}</div>
                    <div>
                      Under Warranty:{" "}
                      {modalDetailsData.underWarranty ? "true" : "false"}.
                    </div>
                  </div>
                </Grid>
                <Grid
                  className="req_action_form"
                  container
                  item
                  xs={12}
                  spacing={12}
                  sm={12}
                >
                  <div className="top_border w-100 mt-1"></div>
                </Grid>
                <Grid
                  className="req_action_form"
                  container
                  item
                  xs={12}
                  spacing={12}
                  sm={12}
                >
                  <strong className="pass_word">Reason</strong>
                </Grid>
                <Grid
                  className="req_action_form"
                  container
                  item
                  xs={12}
                  spacing={12}
                  sm={12}
                >
                  <input
                    type="text"
                    name="reason"
                    className="reason_type"
                    onChange={(e) => {
                      handleChangeReason(e);
                    }}
                  />
                </Grid>
                {validationsErr && validationsErr.errors.reason && (
                  <Grid
                    className="req_action_form"
                    container
                    item
                    xs={12}
                    spacing={12}
                    sm={12}
                  >
                    <div class="errMsgConatiner">
                      {validationsErr.errors.reason}
                    </div>
                  </Grid>
                )}
                <Grid
                  className="req_action_form"
                  container
                  item
                  xs={12}
                  spacing={12}
                  sm={12}
                >
                  <span className="req_padding">
                    Please enter the Reason behind refusing the request
                  </span>
                </Grid>
                <Grid
                  className="req_action_form"
                  container
                  item
                  xs={12}
                  spacing={12}
                  sm={12}
                >
                  <span className="req_note">
                    Note that you can not undo this operation.
                  </span>
                </Grid>
              </Grid>
            </DialogContent>

            <DialogActions>
              <Button
                onClick={() => handleRefuseKey(popupCancelId)}
                variant="contained"
                size="medium"
                color="primary"
              >
                OK
              </Button>
              <Button
                onClick={() => {
                  setOpen_refuse(false);
                }}
                variant="contained"
                size="medium"
                color="secondary"
              >
                Cancel
              </Button>
            </DialogActions>
          </Dialog>
        ) : null}
      </div>

      {/* ---------------------------- popup of served ------------------------------- */}
      <div>
        {userrole === "corporate" ? (
          <Dialog
            style={{ padding: "10px" }}
            fullWidth={true}
            open={open_serve}
            onClose={handleClose_serve}
            className={classes.dialog_padding}
            maxWidth="sm"
            aria-labelledby="responsive-dialog-title"
          >
            <div class="action-dialog_top">
              <DialogTitle
                id="alert-dialog-slide-title"
                class="action_dialog_title text-center"
              >
                Serve keycode request
              </DialogTitle>
              <div
                class="action-dialog_icon"
                onClick={() => handleClose_serve()}
              >
                <IconButton aria-label="close" className={classes.closeButton}>
                  <CloseIcon />
                </IconButton>
              </div>
            </div>

            <DialogContent className="user_form">
              <Grid className="req_action_form" container spacing={6}>
                <Grid container className="req_action_form" item xs={12} sm={6}>
                  <div className="req_padding">
                    <div>VIN:{modalDetailsData.vin}.</div>
                    <div>
                      Urgent:{modalDetailsData.urgent ? "true" : "false"}.
                    </div>
                    <div>Requested:{modalDetailsData.updatedAt}.</div>
                  </div>
                </Grid>
                <Grid className="req_action_form" container item xs={12} sm={6}>
                  <div className="right_data">
                    <div>Job#:{modalDetailsData.jobRequestNumber}</div>
                    <div>
                      Under Warranty:
                      {modalDetailsData.underWarranty ? "true" : "false"}.
                    </div>
                  </div>
                </Grid>

                <Grid
                  className="req_action_form"
                  container
                  item
                  xs={12}
                  sm={12}
                >
                  <div className="top_border w-100 pb-1"></div>
                </Grid>

              {modalDetailsData.supportFiles && (
                <>
                <Grid
                  className="req_action_form img_margin"
                  container
                  item
                  xs={12}
                  sm={12}
                >
                <div className="marginAuto">
                  <div className="text-center">
                    <h2 className="supported_documents">SUPPORTED DOCUMENTS</h2>
                  </div>
                    <div className="image-row">
                      <div className="images">
                        <div className="img-box">
                        {modalDetailsData.supportFiles
                          .split(",")
                          .map((item) => (
                            <>
                            <div className="img-download">
                              <img
                                className="img-hw"
                                src={item}
                                onClick={() => handleImageEvent(item)}
                                alt="download"
                              />
                              <div
                                className="img-blur"
                                onClick={() => downloadImg(item)}
                              >
                                <GetAppIcon fontSize="small" />
                              </div>
                            </div>
                            </>
                          ))}
                          </div>
                      </div>
                    </div>
                </div>
                </Grid>
                 <Grid
                 className="req_action_form"
                 container
                 item
                 xs={12}
                 sm={12}
               >
                 <div className="top_border w-100 pb-1"></div>
               </Grid>
               </>
              )}

               

                <Grid className="keycode_padd" container item xs={12} sm={12}>
                  <Grid
                    className="req_action_form"
                    container
                    item
                    xs={6}
                    sm={3}
                  >
                    <strong className="">Ignition</strong>
                    <input
                      type="text"
                      name="ignition"
                      className="key_type"
                      placeholder=""
                      onChange={(e) => handleChangeServe(e)}
                    ></input>
                  </Grid>

                  <Grid
                    className="req_action_form"
                    container
                    item
                    xs={6}
                    sm={3}
                  >
                    <strong className="">PIN</strong>
                    <input
                      type="text"
                      name="pin"
                      className="key_type"
                      placeholder=""
                      onChange={(e) => handleChangeServe(e)}
                    ></input>
                  </Grid>

                  <Grid
                    className="req_action_form"
                    container
                    item
                    xs={6}
                    sm={3}
                  >
                    <strong className="">Auth PIN</strong>
                    <input
                      type="text"
                      name="auth_pin"
                      className="key_type"
                      placeholder=""
                      onChange={(e) => handleChangeServe(e)}
                    ></input>
                  </Grid>

                  <Grid
                    className="req_action_form"
                    container
                    item
                    xs={6}
                    sm={3}
                  >
                    <strong className="">Mechanical Code</strong>
                    <input
                      type="text"
                      name="mechanical_code"
                      className="key_type"
                      placeholder=""
                      onChange={(e) => handleChangeServe(e)}
                    ></input>
                  </Grid>
                </Grid>
                <Grid
                  className="req_action_form"
                  container
                  item
                  xs={12}
                  sm={12}
                >
                  <span className="req_padding">
                    Please enter the keycodes for the above request.
                  </span>
                </Grid>
              </Grid>
            </DialogContent>

            <DialogActions>
              <Button
                onClick={() => {
                  handleServeKey(modalDetailsData);
                }}
                variant="contained"
                size="medium"
                color="primary"
              >
                OK
              </Button>
              <Button
                onClick={() => {
                  handleServeCancel(modalDetailsData);
                }}
                variant="contained"
                size="medium"
                color="secondary"
              >
                Cancel
              </Button>
            </DialogActions>
          </Dialog>
        ) : null}
      </div>

      <div className={classes.loaderDiv}>
        {/* <Loader /> */}
        {loaderToggle ? (
          <Backdrop
            className={classes.backdrop}
            open={true}
            onClick={handleClose}
          >
            <CircularProgress color="inherit" />
          </Backdrop>
        ) : null}
      </div>

      {/* -------------------------------pop up of cancel----------------------- */}
      {userrole === "dealer" ? (
        <Dialog
          style={{ padding: "10px" }}
          fullWidth={true}
          open={open_cancel}
          onClose={handleCancel}
          className={classes.dialog_padding}
          maxWidth="sm"
          aria-labelledby="responsive-dialog-title"
        >
          <div class="action-dialog_top">
            <DialogTitle
              id="alert-dialog-slide-title"
              class="action_dialog_title text-center"
            >
              <span className="warningIcon">
                <WarningIcon />
              </span>
              Cancel your request?
            </DialogTitle>
            <div
              class="action-dialog_icon"
              onClick={() => setOpen_cancel(false)}
            >
              <IconButton aria-label="close" className={classes.closeButton}>
                <CloseIcon />
              </IconButton>
            </div>
          </div>

          <DialogContent className="user_form">
            <Grid className="req_action_form" container spacing={6}>
              <Grid
                container
                className="req_action_form"
                item
                xs={12}
                spacing={12}
                sm={12}
              >
                <span style={{ textAlign: "justify" }}>
                  You are about to cancel the keycode request.If you proceed
                  with this action KeycodeCentro will not be able to serve your
                  request.
                </span>
              </Grid>
            </Grid>
          </DialogContent>

          <DialogActions>
            <Button
              onClick={() => handleCancelKey(popupCancelId)}
              variant="contained"
              size="medium"
              color="secondary"
            >
              Cancel Request
            </Button>
            <Button
              variant="contained"
              size="medium"
              color="primary"
              onClick={() => setOpen_cancel(false)}
            >
              Keep Request
            </Button>
          </DialogActions>
        </Dialog>
      ) : null}
    </div>
  );
}
