import React, { useRef} from "react";
import {
  Route,
  Switch,
  Redirect,
  BrowserRouter as Router,
} from "react-router-dom";
// components
import Layout from "./Layout";

// pages
import Error from "../pages/error";
import Login from "../pages/login";
import ForgotPassword from "../pages/forgotpassword";
import Activate from "../pages/activate";
import ResetPassword from "../pages/resetPassword/ResetPassword";
import Thankyou from "../pages/thankyou";
// context
import { signOut, useUserDispatch } from "../context/UserContext";
import { useUserState } from "../context/UserContext";
import IdleTimer from "react-idle-timer";

export default function App() {
  // global
  var userDispatch = useUserDispatch();

  //automatic logout  
  const onIdle = () => {
    localStorage.removeItem("id_token");
    localStorage.removeItem("jwtauthtoken");
    signOut("logout", userDispatch, "test");
  };
  var { isAuthenticated } = useUserState();

  const IdleTimerRef = useRef(null);
//   window.onbeforeunload = function(e) {
//     e.preventDefault();
//     e.returnValue = '';
//   }
//   window.onunload = function(event)
//  {
//   localStorage.removeItem("id_token");
//  }
  return (
    <>
      <IdleTimer ref={IdleTimerRef} timeout={15 * 60000} onIdle={onIdle} />
      <Router>
        <Switch>
          <Route exact path="/forgotpassword" component={ForgotPassword} />
          <Route exact path="/activate/:id" component={Activate} />
          <Route exact path="/reset-password/:id" component={ResetPassword} />
          <Route exact path="/forgot-password-thankyou" component={Thankyou} />
          <Route exact path="/" render={() => <Redirect to="/app/Users" />} />
          <Route
            exact
            path="/app"
            render={() => <Redirect to="/app/Users" />}
          />
          <PrivateRoute path="/app" component={Layout} />
          <PublicRoute path="/login" component={Login} />
          <Route component={Error} />
          {/* <Route
          exact
          path="/"
          render={() => <Redirect to="/app/forgot-password" />}
        />
        <Route
          exact
          path="/app"
          render={() => <Redirect to="/app/forgot-password" />}
        />
        <PrivateRoute path="/app" component={Layout} />
        <PublicRoute path="/forgotpassword" component={ForgotPassword} /> */}
        </Switch>
      </Router>
    </>
  );

  // #######################################################################

  function PrivateRoute({ component, ...rest }) {
    return (
      <Route
        {...rest}
        render={(props) =>
          isAuthenticated ? (
            React.createElement(component, props)
          ) : (
            <Redirect
              to={{
                pathname: "/login",
                state: {
                  from: props.location,
                },
              }}
            />
          )
        }
      />
    );
  }

  function PublicRoute({ component, ...rest }) {
    return (
      <Route
        {...rest}
        render={(props) =>
          isAuthenticated ? (
            <Redirect
              to={{
                pathname: "/",
              }}
            />
          ) : (
            React.createElement(component, props)
          )
        }
      />
    );
  }
}
