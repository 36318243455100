import React from "react";
var UserStateContext = React.createContext();
var UserDispatchContext = React.createContext();

function userReducer(state, action) {
  switch (action.type) {
    case "LOGIN_SUCCESS":
      return { ...state, isAuthenticated: true, usersDataState: action.payload };
    case "LOGIN_FAILURE":
      return { ...state, isAuthenticated: false, isRequired: action.payload };
    case "SIGN_OUT_SUCCESS":
      return { ...state, isAuthenticated: false, successRes: action.payload };
    case "ALERT_POPUP_OPEN":
      return { ...state, setopen: action.payload };
    case "FORGOT_SUCCESS":
      return { ...state, isAuthenticated: false, successRes: action.payload };
    case "UPDATE_PROFILE":
      return { ...state, updatedProfileData: action.payload };
    case "UPDATE_PROFILE_MSG":
      return { ...state, updatedProfileMsg: action.payload };
    case "GLOBAL_SUCCESS_MSG":
      return { ...state, globalSuccessMsg: action.payload };
    case "GLOBAL_ERR_MSG":
      return { ...state, globalErrMsg: action.payload };
    case "GLOBAL_OPENED_ROW":
      return { ...state, openedRowState: action.payload };
    default: {
      throw new Error(`Unhandled action type: ${action.type}`);
    }
  }
}

function UserProvider({ children }) {
  var [state, dispatch] = React.useReducer(userReducer, {
    isAuthenticated: !!localStorage.getItem("id_token"),
    isRequired: "", successRes: "", setopen: false
  });

  return (
    <UserStateContext.Provider value={state}>
      <UserDispatchContext.Provider value={dispatch}>
        {children}
      </UserDispatchContext.Provider>
    </UserStateContext.Provider>
  );
}

function useUserState() {
  var context = React.useContext(UserStateContext);
  if (context === undefined) {
    throw new Error("useUserState must be used within a UserProvider");
  }
  return context;
}

function useUserDispatch() {
  var context = React.useContext(UserDispatchContext);
  if (context === undefined) {
    throw new Error("useUserDispatch must be used within a UserProvider");
  }
  return context;
}

export { UserProvider, useUserState, useUserDispatch, loginUser, signOut, alertPopup, fogotpassword, updateProfile, updateProfileMsg, setGlobalSuccessMsg, setGlobalErrMsg, setGlobalOpenedRow };

function loginUser(dispatch, login, password, captchaValue, history, userDetailsData) {
  dispatch({ type: "LOGIN_SUCCESS", payload: userDetailsData });
  if (userDetailsData && userDetailsData !== null && userDetailsData !== undefined) {
    userDetailsData.role === "dealer" ? history.push('/app/Request') :
      userDetailsData.role === "corporate" ? history.push('/app/Request')
        : history.push('/app/Users')
  }
  setTimeout(() => {
    localStorage.setItem('id_token', 1)
  }, 2000);
}

function signOut(msg, dispatch, history) {
  localStorage.removeItem("id_token");
  localStorage.removeItem("jwtauthtoken");
  localStorage.removeItem("userdetails");
  dispatch({ type: "SIGN_OUT_SUCCESS", payload: { successmsg: msg } });
}

function alertPopup(dispatch, conform) {
  dispatch({ type: "ALERT_POPUP_OPEN", payload: { setopen: false, conform: conform } });
}

function fogotpassword(msg, history, dispatch) {
  setTimeout(() => {
    history.push('/forgot-password-thankyou')
  }, 2000);

  dispatch({ type: "FORGOT_SUCCESS", payload: { successmsg: msg } });
}

/** Update profile */
function updateProfile(data, dispatch) {
  dispatch({ type: "UPDATE_PROFILE", payload: { data } });
}

/** Update profile message */
function updateProfileMsg(data, dispatch) {
  dispatch({ type: "UPDATE_PROFILE_MSG", payload: { data } });
}

/** Set succes message in global state */
function setGlobalSuccessMsg(data, dispatch, statusData, visibleData) {
  data !== "" && dispatch({ type: "GLOBAL_SUCCESS_MSG", payload: { msg: data, status: statusData, visibleStatus: visibleData } });
}

/** Set error message in global state */
function setGlobalErrMsg(data, dispatch) {
  data !== "" && dispatch({ type: "GLOBAL_ERR_MSG", payload: { data } });
}

/** Set left panel open state in global state */
function setGlobalOpenedRow(data, dispatch) {
  data !== "" && dispatch({ type: "GLOBAL_OPENED_ROW", payload: { data } });
}
