import { makeStyles } from "@material-ui/styles";

export default makeStyles(theme => ({
  card: {
    minHeight: "100%",
    display: "flex",
    flexDirection: "column",
  },
  visitsNumberContainer: {
    display: "flex",
    alignItems: "center",
    flexGrow: 1,
    paddingBottom: theme.spacing(1),
  },
  progressSection: {
    marginBottom: theme.spacing(1),
  },
  progressTitle: {
    marginBottom: theme.spacing(2),
  },
  progress: {
    marginBottom: theme.spacing(1),
    backgroundColor: 'rgb(236, 236, 236)',
  },
  pieChartLegendWrapper: {
    height: "100%",
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "flex-end",
    marginRight: theme.spacing(1),
  },
  legendItemContainer: {
    display: "flex",
    alignItems: "center",
    marginBottom: theme.spacing(1),
  },
  fullHeightBody: {
    display: "flex",
    flexGrow: 1,
    flexDirection: "column",
    justifyContent: "space-between",
  },
  tableWidget: {
    overflowX: "auto",
  },
  progressBarPrimary: {
    backgroundColor: theme.palette.primary.main,
  },
  progressBarWarning: {
    backgroundColor: theme.palette.warning.main,
  },
  performanceLegendWrapper: {
    display: "flex",
    flexGrow: 1,
    alignItems: "center",
    marginBottom: theme.spacing(1),
  },
  legendElement: {
    display: "flex",
    alignItems: "center",
    marginRight: theme.spacing(2),
  },
  legendElementText: {
    marginLeft: theme.spacing(1),
  },
  serverOverviewElement: {
    display: "flex",
    alignItems: "center",
    maxWidth: "100%",
  },
  serverOverviewElementText: {
    minWidth: 145,
    paddingRight: theme.spacing(2),
  },
  serverOverviewElementChartWrapper: {
    width: "100%",
  },
  mainChartBody: {
    overflowX: "auto",
  },
  mainChartHeader: {
    width: "100%",
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
    [theme.breakpoints.only("xs")]: {
      flexWrap: "wrap",
    },
  },
  mainChartHeaderLabels: {
    display: "flex",
    alignItems: "center",
    [theme.breakpoints.only("xs")]: {
      order: 3,
      width: "100%",
      justifyContent: "center",
      marginTop: theme.spacing(3),
      marginBottom: theme.spacing(2),
    },
  },
  mainChartHeaderLabel: {
    display: "flex",
    alignItems: "center",
    marginLeft: theme.spacing(3),
  },
  mainChartSelectRoot: {
    borderColor: theme.palette.text.hint + "80 !important",
  },
  mainChartSelect: {
    padding: 10,
    paddingRight: 25,
  },
  notificationPop : {
    border: "3px dotted #ad4b5c",
    backgroundColor: "pink",
    width: "30%",
    position: "absolute",
    padding: "25px",
    right: "30px",
    bottom : "0",
    height: "250px"

  },
  mainChartLegentElement: {
    fontSize: "18px !important",
    marginLeft: theme.spacing(1),
  },
  success: {
    backgroundColor: theme.palette.success.main,
    color: '#fff',
  },
  warning: {
    backgroundColor: theme.palette.warning.main,
    color: '#fff',
  },
  secondary: {
    backgroundColor: theme.palette.secondary.main,
    color: '#fff',
  },
  newReqBtn: {
    height:"45px"
  },
  dialog_padding:{
    padding:"30px"
  },
  dialog_width:{
    maxWidth:"670px" ,
  },
  text_or:{
    textAlign:"center",
    margin:"0px"
  },
  dragTextP :{
      margin: "0px",
      paddingTop: "16px",
  },
  uploadImageButton:{
    background: "#3CD4A0",
    color: "#fff",
    cursor: "pointer", 
    padding: "7px", 
    borderRadius: "4px",
    marginTop:"8px",
    marginBottom:"8px"},
    
  getRed: {
    color: "red",
    fontWeight: "bold",
    cursor: "pointer",
  },
  getBlue: {
    color: "blue",
    fontWeight: "bold",
    cursor: "pointer",
  },
  getGreen: {
    color: "green",
    fontWeight: "bold",
    cursor: "pointer",
  },
  table: {
    minWidth: 300,
  }
  , loaderDiv: {
    width: "100%",
    textAlign: "center",
    marginTop: "20px",
  },
  backdrop: {
    zIndex: 99999,
    color: "#fff",
    backgroundColor: "rgba(0,0,0,0.5)",
  },
  padding:{
    padding:"20px"
  },
  successMsgContainer:{
    margin: "0 0 20px 0",
    display: "flex",
    justifyContent: "center",
    width: "100%"
  },
  formControl: {
    margin: theme.spacing(1),
    minWidth: 120,
  },
  selectEmpty: {
    marginTop: theme.spacing(2),
  },
  primary: {
    backgroundColor: theme.palette.primary.main,
    color: '#fff',
  },
  info: {
    backgroundColor: "#9013fe",
    color: '#fff',
  },
  chipPosittion: {
    display: "inline",
    paddingTop: '2px',
    paddingBottom: '2px',
    height: "16px",
    fontSize: "11px",
    fontWeight: "bold",
  },
  buttonsContainer: {
    display: "flex",
    // flexDirection: "column",
    alignItems: "flex-start",
width:"max-content",
    marginTop: theme.spacing(2),
  },
  notificationCallButton: {
    color: "white",
    marginBottom: theme.spacing(1),
    textTransform: "none",
    fontSize: "0.8125rem",
    padding: "2px 8px"
  },
  textFieldUnderline: {
    "&:before": {
      borderBottomColor: theme.palette.primary.light,
    },
    "&:after": {
      borderBottomColor: theme.palette.primary.main,
    },
    "&:hover:before": {
      borderBottomColor: `${theme.palette.primary.light} !important`,
    },
  },
  textField: {
    borderBottomColor: theme.palette.background.light,
  },
  errorMessage: {
    textAlign: "center",
    backgroundColor:"#e0abab",
    // width: "auto",
    fontSize: "17px",
    padding: "5px",
    // textAlign: "center",
    border: "2px solid #e46c82",
    color: "black",
  },
  notificationPagination: {
    display: "flex",
    marginTop:"25px"
  },
  pdf: {
    marginLeft: "2px",
    color:"blue",
    cursor:"pointer",
    alignItems:"center"
  },
  terms: {
    alignItems: "center",
    marginLeft: "120px"
  },
  margin :{
    marginBottom: "10px"
  }
}));
