import { API_BASE_URL } from '../../apis/config';
import axios from 'axios';

/** Get All Tickets */
export const allSupportTickets = async (authtoken,pageNo) => {
  return await axios
    .get(
      `${API_BASE_URL}/ticket/?page=${pageNo}`, { headers: { "auth-token": authtoken } })
}

/** Get Tickets with status */
export const browseTicket = async (authtoken,status,pageNo) => {
  return await axios
    .get(
      `${API_BASE_URL}/ticket/?status=${status}&page=${pageNo}`, { headers: { "auth-token": authtoken } })
}

/** Add new Ticket */
export const addNewTicket = async (authtoken, data) => {
    return await axios.post(`${API_BASE_URL}/ticket`, data, {
      headers: { "auth-token": authtoken },
    });
};

/** Close Ticket */
export const closeTicket = async (authtoken, id, data) => {
   return await axios.put(`${API_BASE_URL}/ticket/${id}`, data, {
    headers: { "auth-token": authtoken },
  });
};