import React, { useEffect, useState, useRef } from "react";
import {
  Grid,
  CircularProgress,
  Typography,
  Button,
  TextField,
  Fade,
} from "@material-ui/core";
import axios from 'axios';
import { withRouter, useHistory } from "react-router-dom";
import EthCrypto from 'eth-crypto'
import useStyles from "./styles";
import ReCAPTCHA from "react-google-recaptcha";
import corporateLogo from "../../images/logo_placeholder-square.png";
import {
  useUserDispatch,
  loginUser,
} from "../../context/UserContext";
import { LOGIN_API } from '../../apis/config'

const TEST_SITE_KEY = "6LeNOhoaAAAAACOn2KH2Ey6orwqI7FTOrREnP88I";
const DELAY = 1000;

function Login(props) {
  var classes = useStyles();
  let history = useHistory();
  var userDispatch = useUserDispatch();
  var [isLoading, setIsLoading] = useState(false);
  var [errorEmail, setErrorEmail] = useState("");
  var [errorPassword, setErrorPassword] = useState("");
  var [errorCapcha, setErrorCapcha] = useState(0);
  var [loginValue, setLoginValue] = useState("");
  var [passwordValue, setPasswordValue] = useState("");
  const [captchaValue, setCaptchaValue] = useState("");
  const [mainError, setMainError] = useState("")
  const [load, setLoad] = useState(false);
  const _reCaptchaRef = useRef();

  useEffect(() => {
    setTimeout(() => {
      setLoad(true);
    }, DELAY);
  }, []);

  /** Captcha*/
  const handleChangeCaptcha = (value) => {
    setErrorCapcha("")
    setCaptchaValue(value);
  };

  /** Change Email*/
  const HandleChangeEmail = (event) => {
    setLoginValue(event.target.value);
    setErrorEmail("");
    setMainError("");
  };

  /** Change password*/
  const HandleChangePassword = (event) => {
    setPasswordValue(event.target.value);
    setErrorPassword("");
    setMainError("");
  };

  /** Forgot password redirect */
  const forgotPassword = () => {
    history.push("/forgotpassword");
  };

  /** Login */
  const HandleChangeLogin = () => {
    setIsLoading(true);
    if (loginValue === "") {
      setErrorEmail("Please Enter Your Email");
      setIsLoading(false);
    }
    else if (!/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(loginValue)) {
      setErrorEmail("Must be an email");
      setIsLoading(false);
    }
    else if (passwordValue === "") {
      setErrorPassword("Password is not an optional");
      setIsLoading(false);
    }
    else {
      const hashedPasswords = EthCrypto.hash.keccak256(passwordValue);
      let AllData = {
        email: loginValue,
        hashedPassword: hashedPasswords
      }
      axios.post(
        `${LOGIN_API}`, AllData)
        .then((res) => {
          setIsLoading(false);
          if (res.data.success === false) {
            setMainError(res.data.message)
          }
          if (res.data.success === true) {
                       localStorage.setItem("jwtauthtoken", res.data.JWT);
            localStorage.setItem("userdetails", JSON.stringify(res.data.user));
            loginUser(
              userDispatch,
              loginValue,
              passwordValue,
              captchaValue,
              props.history,
              res.data.user
            )
            setIsLoading(false);
          }
        })
        .catch((error) => {
          setMainError(error.response.data.errors)
          setIsLoading(false);
        });
    }
  }

  return (
    <Grid container className={classes.container}>
      <div className={classes.formContainer}>
        <div className={classes.form}>

          <React.Fragment>
            <Button size="large" className={classes.googleButton}>
              <img
                src={corporateLogo}
                alt="Login"
                className={classes.googleIcon}
              />
            </Button>
            
            {mainError && mainError.length > 0 && mainError.map((item,index)=> {
              return(
              <Fade in={item.msg}>
                <Typography color="secondary" className={classes.errorMessage}>
                  {item.msg}
                </Typography>
              </Fade>
              )
            })}
            
            <TextField
              id="email"
              InputProps={{
                classes: {
                  underline: classes.textFieldUnderline,
                  input: classes.textField,
                },
              }}
              value={loginValue}
              onChange={(e) => HandleChangeEmail(e)}
              margin="normal"
              placeholder="Please Enter Your Email"
              type="email"
              fullWidth={true}
            />
            <Fade in={errorEmail}>
              <Typography color="secondary" className={classes.errorMessage}>
                {errorEmail}
              </Typography>
            </Fade>
            <TextField
              id="password"
              InputProps={{
                classes: {
                  underline: classes.textFieldUnderline,
                  input: classes.textField,
                },
              }}
              value={passwordValue}
              onChange={(e) => HandleChangePassword(e)}
              margin="normal"
              placeholder="Password"
              type="password"
              fullWidth={true}
            />

            {errorPassword && errorPassword ? (
              <Fade in={errorPassword}>
                <Typography
                  color="secondary"
                  className={classes.errorMessage}
                >
                  {errorPassword}
                </Typography>
              </Fade>
            ) : null}

            <div className={classes.formButtons}>
              <Button
                color="primary"
                size="large"
                className={classes.forgetButton}
                onClick={forgotPassword}
              >
                Forget Password
                </Button>
            </div>
          </React.Fragment>

          <span className={classes.captchaTitle}>
            Please check the box below to proceed.
          </span>
          <div className={classes.captchaDiv}>
            {load && (
              <ReCAPTCHA
                style={{ display: "inline-block" }}
                theme="light"
                ref={_reCaptchaRef}
                sitekey={TEST_SITE_KEY}
                onChange={handleChangeCaptcha}
              />
            )}
          </div>

          {errorCapcha && errorCapcha ? (
            <Fade in={errorCapcha}>
              <Typography
                color="secondary"
                className={classes.errorMessage}
              >
                {errorCapcha}
              </Typography>
            </Fade>
          ) : null}

          <div className={classes.loginBtnDiv}>
            <Button className={classes.loginBtn}
              onClick={() =>
                HandleChangeLogin()
              }
              variant="contained"
              color="primary"
              size="large"
            >
              Login {isLoading ? (
                <CircularProgress size={26} className={classes.loginLoader} />
              ) : null}
            </Button>
          </div>
        </div>
        <span className={classes.poweredBy}>Powered By Smart Otomation Solutions</span>
      </div>
    </Grid>
  );
}
export default withRouter(Login);
