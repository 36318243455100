import React, { useEffect, useState } from "react";
import "../Profile/Profile.css";
import { Button } from "@material-ui/core";
import Grid from "@material-ui/core/Grid";
import { user_profile } from "./ProfileAPI";
import {
  useUserDispatch,
  updateProfile,
  setGlobalSuccessMsg,
} from "../../context/UserContext";
import DialogContent from "@material-ui/core/DialogContent";
import DialogActions from "@material-ui/core/DialogActions";

export default function Profile() {
  var userDispatch = useUserDispatch();
  let userdetails = JSON.parse(localStorage.getItem("userdetails"));
  let userid = userdetails._id;
  const authtoken = localStorage.getItem("jwtauthtoken");
  const [loadComponent, setLoadComponent] = useState([]);
  const [validationsErr, setValidationsErr] = useState("");
  const [allData, setAllData] = useState({
    name: userdetails.name,
    email: userdetails.email,
    phone: userdetails.phone,
  });

  useEffect(() => {}, [loadComponent]);

  // ---------------validation----------//
  const handleChange = (event) => {
    setAllData({ [event.target.name]: event.target.value });
  };
  /** Submit Profile API*/
  const handleOnsubmit = () => {
    if (handleValidation()) {
      const profileDealer = user_profile(authtoken, userid, allData);
      profileDealer
        .then((response) => {
          localStorage.setItem(
            "userdetails",
            JSON.stringify(response.data.data.user),
          );
          updateProfile(response.data.data.user, userDispatch);

          if (response.data.success === true)
            setGlobalSuccessMsg(
              "User has been successfully Updated",
              userDispatch,
              "success",
            );
          setLoadComponent(response);
        })
        .catch((error) => {
          let errors = {};
          error.response.data.errors &&
            error.response.data.errors.map((item) => {
              return (errors[item.param] = item.msg);
            });
          errors && Object.entries(errors).length > 0
            ? setValidationsErr({ errors: errors })
            : error.response &&
              setGlobalSuccessMsg(
                error.response.data.message,
                userDispatch,
                "error",
              );
          setValidationsErr({ errors: errors });
        });
    } else {
    }
  };
  /**Validation of Form*/
  const handleValidation = () => {
    let fields = allData;
    let errors = {};
    let formIsValid = true;

    if (fields.name && !fields["name"]) {
      formIsValid = false;
      errors["name"] = "Name can not be empty";
    }
    if (fields["phone"] && fields["phone"].length !== 14) {
      formIsValid = false;
      errors["phone"] = "Phone number must be 14 digits";
    }
    if (fields["phone"] && !fields["phone"]) {
      formIsValid = false;
      errors["phone"] = "Phone number can not be empty";
    }
    setValidationsErr({ errors: errors });
    return formIsValid;
  };

  return (
    <div className="form_profile">
      <DialogContent className="user_form form_dealer">
        <Grid container spacing={3} fullwidth="true">
          <Grid className="comman_label" container item xs={12} sm={3}>
            <label className="comman_label">Name</label>
          </Grid>
          <Grid container className="comman_label" item xs={12} sm={9}>
            <input
              type="text"
              className="input_type"
              placeholder="Name"
              defaultValue={userdetails.name}
              name="name"
              onChange={handleChange}
            ></input>
            {validationsErr && validationsErr.errors.name && (
              <div class="errMsgConatiner">{validationsErr.errors.name}</div>
            )}
          </Grid>

          <Grid className="comman_label" container item xs={12} sm={3}>
            <label className="comman_label">Mobile</label>
          </Grid>
          <Grid className="comman_label" container item xs={12} sm={9}>
            <input
              type="text"
              className="input_type"
              placeholder="Mobile"
              defaultValue={userdetails.phone}
              name="phone"
              onChange={handleChange}
            ></input>
            {validationsErr && validationsErr.errors.phone && (
              <div class="errMsgConatiner">{validationsErr.errors.phone}</div>
            )}
          </Grid>

          <Grid className="comman_label" container item xs={12} sm={3}>
            <label className="comman_label">Email</label>
          </Grid>
          <Grid className="comman_label" container item xs={12} sm={9}>
            <input
              disabled
              readOnly
              type="text"
              className="input_type"
              placeholder="Email"
              defaultValue={userdetails.email}
              name="email"
              onChange={handleChange}
            ></input>
            {validationsErr && validationsErr.errors.email && (
              <div class="errMsgConatiner">{validationsErr.errors.email}</div>
            )}
          </Grid>
        </Grid>
      </DialogContent>

      {userid !== "corporate" ? (
        <DialogActions style={{ justifyContent: "center" }}>
          <Button
            onClick={handleOnsubmit}
            variant="contained"
            size="medium"
            color="secondary"
          >
            OK
          </Button>
        </DialogActions>
      ) : null}
    </div>
  );
}
