import React, { useEffect, useState } from "react";
import {
  Chip,
  Table,
  TableContainer,
  TableRow,
  Paper,
  TableFooter,
  Dialog,
  Grid,
  Backdrop,
  TextareaAutosize,
  CircularProgress,
  DialogTitle,
  DialogActions,
  DialogContent,
  Accordion,
  AccordionSummary,
  AccordionDetails,
  Box,
  FormControl,
  Select,
  MenuItem,
  } from "@material-ui/core";
import { Typography } from "../../components/Wrappers";
import IconButton from "@material-ui/core/IconButton";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import {
  allSupportTickets,
  addNewTicket,
  closeTicket,
  browseTicket,
} from "./supportTicketsAPI";
import useStyles from "./styles";
import CloseIcon from "@material-ui/icons/Close";
import { Button } from "../../components/Wrappers/Wrappers";
import classnames from "classnames";
import Pagination from "@material-ui/lab/Pagination";
import {
  useUserDispatch,
  setGlobalSuccessMsg,
} from "../../context/UserContext";

const defaultProps = {
  bgcolor: "background.paper",
  m: 1,
  border: 1,
};

export default function SupportTickets(props) {
  var userDispatch = useUserDispatch();
  const classes = useStyles();
  const [page, setPage] = React.useState(1);
  const [open, setOpen] = React.useState(false);
  const [filterPage, setFilterPage] = React.useState(1);
  const [noOfPages, setNoOfPages] = useState();
  const [loaderToggle, setLoaderToggle] = useState(true);
  const [ticketStatus, setTicketStatus] = useState({
    status: true,
    message: "",
  });
  const [ticketId, setticketId] = useState("");
  const [allTicketsData, setAllTicketsData] = useState([]);
  const [ticketCloseData, setticketCloseData] = useState({
    adminComment: "",
  });
  const [loadComponent, setLoadComponent] = useState([]);
  const [allFomrData, setallFomrData] = useState({
    ticketType: "",
    ticketText: "",
  });
  const [openClose, setopenClose] = useState(false);
  const [validationsErr, setValidationsErr] = useState("");
  const [filterValue, setFilterValue] = React.useState("all");
  const [isFilterChanged, setIsFilterChanged] = React.useState(false);

  const authtoken = localStorage.getItem("jwtauthtoken");
  let userdetails = JSON.parse(localStorage.getItem("userdetails"));
  let userrole = userdetails.role;
  let statusSlug = {
    open: "Open",
    closed: "Closed",
  };
  const itemsPerPage = 10;

  useEffect(() => {
    setLoaderToggle(true);
    setIsFilterChanged(false);
     const browseAllTicketstApi = (pageNo) => {
    setLoaderToggle(true);
    const supportTickets = allSupportTickets(
      localStorage.getItem("jwtauthtoken"),
      pageNo,
    );
    supportTickets
      .then((response) => {
        setLoaderToggle(false);
        setAllTicketsData(response.data.data.tickets);
        setTicketStatus(()=>({
          ...ticketStatus,
          status: response.data.success,
          message: "",
        }));
        setNoOfPages(Math.ceil(response.data.pagination.total / itemsPerPage));
      })
      .catch((error) => {
        setLoaderToggle(false);
      });
  };
    browseAllTicketstApi(page);
    const timer = setTimeout(() => {}, 10000);
    return () => clearTimeout(timer);
  }, [loadComponent, page]);

  /** Browse Support Tickets API*/
  const browseAllTicketstApi = (pageNo) => {
    setLoaderToggle(true);
    const supportTickets = allSupportTickets(
      localStorage.getItem("jwtauthtoken"),
      pageNo,
    );
    supportTickets
      .then((response) => {
        setLoaderToggle(false);
        setAllTicketsData(response.data.data.tickets);
        setTicketStatus({
          ...ticketStatus,
          status: response.data.success,
          message: "",
        });
        setNoOfPages(Math.ceil(response.data.pagination.total / itemsPerPage));
      })
      .catch((error) => {
        setLoaderToggle(false);
      });
  };
  /** Browse Tickets API*/
  const browseTicketstApi = (statusVal, pageNo) => {
    setLoaderToggle(true);
    const browserequestData = browseTicket(authtoken, statusVal, pageNo);
    browserequestData
      .then((response) => {
        setLoaderToggle(false);
        setAllTicketsData(response.data.data.tickets);
        response.data.pagination &&
          setNoOfPages(
            Math.ceil(response.data.pagination.total / itemsPerPage),
          );
        setTicketStatus({
          ...ticketStatus,
          status: response.data.success,
          message: "",
        });
      })
      .catch((error) => {
        setAllTicketsData([]);
        error.response.data &&
          setTicketStatus({
            ...ticketStatus,
            status: error.response.data.success,
            message: error.response.data.message,
          });
        setLoaderToggle(false);
      });
  };
  /**close Modal*/
  const handleClose = () => {
    setOpen(false);
    setValidationsErr({ errors: "" });
  };
  /** HandleChange Form  */
  const handleChange = (event) => {
    setallFomrData({
      ...allFomrData,
      [event.target.name]:
        event.target.value !== "" ? event.target.value : null,
    });
  };
  /** HandleChange CloseTicket Form  */
  const handleChangeClose = (event) => {
    setticketCloseData({
      ...ticketCloseData,
      [event.target.name]:
        event.target.value !== "" ? event.target.value : null,
    });
  };
  /**Open Modal*/
  const handleClickOpen = () => {
    setOpen(true);
    setValidationsErr({ errors: [] });
  };
  /**close Modal*/
  const handleCloseOpen = (id) => {
    setopenClose(true);
    setticketId(id);
    setValidationsErr({ errors: "" });
  };
  /** Close Ticket API  */
  const handleOnsubmitClose = () => {
    if (handleCloseValidation()) {
      const closeOpenTicket = closeTicket(authtoken, ticketId, ticketCloseData);

      closeOpenTicket
        .then((response) => {
          setGlobalSuccessMsg(
            "Ticket has been successfully closed",
            userDispatch,
            "success",
          );
          setLoadComponent(response);
          setopenClose(false);
        })
        .catch((error) => {
          error.response &&
            setGlobalSuccessMsg(
              error.response.data.message,
              userDispatch,
              "error",
            );
        });
    } else {
      setopenClose(true);
    }
  };
  /** Added Ticket API  */
  const handleOnsubmit = () => {
    if (handleValidation()) {
      const addTicket = addNewTicket(authtoken, allFomrData);
      addTicket
        .then((response) => {
          setLoadComponent(response);
          setGlobalSuccessMsg(
            "Ticket has been successfully Added",
            userDispatch,
            "success",
          );
          setOpen(false);
        })
        .catch((error) => {
          let errors = {};
          error.response.data.errors &&
            error.response.data.errors.map((item) => {
              return (errors[item.param] = item.msg);
            });
          errors && Object.entries(errors).length > 0
            ? setValidationsErr({ errors: errors })
            : error.response &&
              setGlobalSuccessMsg(
                error.response.data.message,
                userDispatch,
                "error",
              );
        });
    } else {
      setOpen(true);
    }
  };
  /** Validation of Ticket Form  */
  const handleValidation = () => {
    let fields = allFomrData;
    let errors = {};
    let formIsValid = true;

    if (!fields["ticketText"]) {
      formIsValid = false;
      errors["ticketText"] = "Ticket Text can not be empty";
    }
    if (!fields["ticketType"]) {
      formIsValid = false;
      errors["ticketType"] = "Please select the Ticket Type";
    }
    setValidationsErr({ errors: errors });
    return formIsValid;
  };
  /** Validation of CloseTicket Form  */
  const handleCloseValidation = () => {
    let fields = ticketCloseData;
    let errors = {};
    let formIsValid = true;

    if (!fields["adminComment"]) {
      formIsValid = false;
      errors["adminComment"] = "Comment can not be empty";
    }
    setValidationsErr({ errors: errors });
    return formIsValid;
  };
  /**Pagination*/
  const handleTicketArr = (value) => {
    let reqArr = [];
    noOfPages !== undefined &&
      allTicketsData
        .slice((value - 1) * itemsPerPage, value * itemsPerPage)
        .map((item, index) => {
          return reqArr.push(item._id);
        });
  };
  /**Pagination*/
  const handlePaginationChange = (event, value) => {
    setPage(value);
    handleTicketArr(value);
  };
  /**Ticket Filter*/
  const handleChangeTicketFilter = (event) => {
    setIsFilterChanged(event.target.value === "all" ? false : true);
    setFilterValue(event.target.value);
    event.target.value === "all" && browseAllTicketstApi(1);
    setFilterPage(1);
    event.target.value !== "all" &&
      browseTicketstApi(
        event.target.value === "all" ? "" : event.target.value,
        filterPage,
      );
  };
  return (
    <>
      <div className="user_button">
        <Grid
          container
          direction="row"
          justify="space-between"
          alignItems="center"
          className="filterContainer"
        >
          <FormControl className={classes.formControl}>
            <Select
              labelId="demo-simple-select-label"
              id="demo-simple-select"
              value={filterValue}
              onChange={handleChangeTicketFilter}
            >
              <MenuItem value="all">
                <em>All</em>
              </MenuItem>
              <MenuItem value={"open"}>Open</MenuItem>
              <MenuItem value={"closed"}>Closed</MenuItem>
            </Select>
          </FormControl>
          {userrole === "corporate" ? (
            <Button
              variant="contained"
              size="medium"
              color="success"
              onClick={handleClickOpen}
            >
              Open New Ticket
            </Button>
          ) : null}
        </Grid>
      </div>
      <>
        <Dialog
          style={{ padding: "10px" }}
          fullWidth={true}
          open={open}
          onClose={() => setOpen(false)}
          className={classes.dialog_padding}
          maxWidth="sm"
          aria-labelledby="responsive-dialog-title"
        >
          <div class="action-dialog_top">
            <DialogTitle
              id="alert-dialog-slide-title"
              class="action_dialog_title text-center"
            >
              Add Ticket
            </DialogTitle>
            <div class="action-dialog_icon" onClick={() => setOpen(false)}>
              <IconButton aria-label="close" className={classes.closeButton}>
                <CloseIcon />
              </IconButton>
            </div>
          </div>
          <DialogContent className="user_form form_dealer">
            <Grid container spacing={3}>
              <Grid className="user_label" container item xs={12} sm={3}>
                <label className="comman_label">Ticket Text</label>
              </Grid>
              <Grid className="user_label" container item xs={12} sm={9}>
                <input
                  type="text"
                  className="input_type"
                  placeholder="Ticket Text"
                  name="ticketText"
                  onChange={handleChange}
                ></input>
                {validationsErr && validationsErr.errors.ticketText && (
                  <div class="errMsgConatiner">
                    {validationsErr.errors.ticketText}
                  </div>
                )}
              </Grid>

              <Grid className="user_label" container item xs={12} sm={3}>
                <label className="comman_label">Ticket Type</label>
              </Grid>
              <Grid className="user_label" container item xs={12} sm={9}>
                <select
                  className="dropdown_city_country"
                  name="ticketType"
                  onChange={handleChange}
                >
                  <option className="dropdown_city_country_edit" value="">
                    Ticket Type
                  </option>
                  <option
                    className="dropdown_city_country_edit"
                    value="add dealer user"
                  >
                    Add Dealer User
                  </option>
                  <option
                    className="dropdown_city_country_edit"
                    value="suspend dealer"
                  >
                    Suspend Dealer
                  </option>
                  <option
                    className="dropdown_city_country_edit"
                    value="suspend dealer user"
                  >
                    Suspend Dealer User
                  </option>
                  <option
                    className="dropdown_city_country_edit"
                    value="resume dealer"
                  >
                    Resume Dealer
                  </option>
                  <option
                    className="dropdown_city_country_edit"
                    value="resume dealer user"
                  >
                    Resume Dealer User
                  </option>
                  <option
                    className="dropdown_city_country_edit"
                    value="delete dealer"
                  >
                    Delete Dealer
                  </option>
                  <option
                    className="dropdown_city_country_edit"
                    value="delete dealer user"
                  >
                    Delete Dealer User
                  </option>
                  <option
                    className="dropdown_city_country_edit"
                    value="technical issue"
                  >
                    Technical Issue
                  </option>
                  <option
                    className="dropdown_city_country_edit"
                    value="general inquery"
                  >
                    General Inquery
                  </option>
                </select>
                {validationsErr && validationsErr.errors.ticketType && (
                  <div class="errMsgConatiner">
                    {validationsErr.errors.ticketType}
                  </div>
                )}
              </Grid>
            </Grid>
          </DialogContent>

          <DialogActions>
            <Button
              onClick={handleOnsubmit}
              variant="contained"
              size="medium"
              color="primary"
            >
              OK
            </Button>
            <Button
              onClick={() => {
                setOpen(false);
              }}
              variant="contained"
              size="medium"
              color="secondary"
            >
              Cancel
            </Button>
          </DialogActions>
        </Dialog>
      </> 
     
      {noOfPages !== undefined &&
        allTicketsData.slice(0, itemsPerPage).map((allTicketsData, index) => (
          <Accordion>
            <AccordionSummary
              expandIcon={<ExpandMoreIcon />}
              aria-label="Expand"
              aria-controls="additional-actions1-content"
              id="additional-actions1-header"
            >
              <Box className={classes.ticketsSummaryMain}>
                <Typography
                  variant="body2"
                  gutterBottom
                  className={classes.textCapitalize}
                >
                  {allTicketsData.ticketType}
                </Typography>

                <Box>
                  <Typography
                    variant="body2"
                    gutterBottom
                    className={classes.textCapitalize}
                  >
                    By: {allTicketsData.userId.name}
                    <Chip
                      className={classes.chipPosittion}
                      label={statusSlug[allTicketsData.status]}
                      classes={{
                        root:
                          classes[
                            allTicketsData.status === "open"
                              ? "info"
                              : allTicketsData.status === "closed"
                              ? "secondary"
                              : "primary"
                          ],
                      }}
                    />
                  </Typography>
                  <Typography variant="body2" gutterBottom>
                    {allTicketsData.updatedAt}
                  </Typography>

                  {allTicketsData.status === "open" && userrole === "admin" ? (
                    <Button
                      variant="contained"
                      color="primary"
                      className={classnames(classes.notificationCallButton)}
                      onClick={() => handleCloseOpen(allTicketsData._id)}
                    >
                      Reply
                    </Button>
                  ) : null}
                </Box>
              </Box>
            </AccordionSummary>
            <AccordionDetails className={classes.displayBlock}>
              <Box
                width={1}
                borderColor="grey.500"
                m={1}
                p={1}
                {...defaultProps}
              >
                <Typography color="textSecondary">
                  {allTicketsData.ticketText}
                </Typography>
              </Box>
              {allTicketsData.adminComment && (
                <Box
                  width={1}
                  borderColor="grey.500"
                  m={1}
                  p={1}
                  {...defaultProps}
                >
                  <Typography color="textSecondary">
                    Admin Comment: {allTicketsData.adminComment}
                  </Typography>
                </Box>
              )}
            </AccordionDetails>
          </Accordion>
        ))}
      <TableContainer component={Paper}>
      {allTicketsData.length === 0 &&
            <p>No Records Found</p>
            }
        {/* <Table className={classes.table} aria-label="customized table">
            <TableHead>
              <TableRow>
                <StyledTableCell align="center">Type</StyledTableCell>
                <StyledTableCell align="center">Text</StyledTableCell>
                <StyledTableCell align="center">Tickets.At</StyledTableCell>
                <StyledTableCell align="center">Status</StyledTableCell>
                <StyledTableCell align="center">Actions</StyledTableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {noOfPages !== undefined &&
                allTicketsData
                  .slice(0, itemsPerPage)
                  .map((allTicketsData, index) => (
                    <StyledTableRow key={index}>
                      <StyledTableCell align="center">
                        {allTicketsData.ticketType}
                      </StyledTableCell>
                      <StyledTableCell align="center">
                        {allTicketsData.ticketText}
                      </StyledTableCell>
                      <StyledTableCell align="center">
                        {allTicketsData.updatedAt}
                      </StyledTableCell>
                      <StyledTableCell align="center">
                        <Chip
                          className={classes.chipPosittion}
                          label={statusSlug[allTicketsData.status]}
                          classes={{
                            root:
                              classes[
                                allTicketsData.status === "open"
                                  ? "info"
                                  : allTicketsData.status === "closed"
                                  ? "secondary"
                                  : "primary"
                              ],
                          }}
                        />
                      </StyledTableCell>
                      <StyledTableCell align="center">
                        <Typography component="a" color="primary">
                          {allTicketsData.adminComment}
                        </Typography>

                        {allTicketsData.status === "open" &&
                        userrole === "admin" ? (
                          <Button
                            variant="contained"
                            color="secondary"
                            className={classnames(classes.notificationCallButton)}
                            onClick={() => handleCloseOpen(allTicketsData._id)}
                          >
                            Close
                          </Button>
                        ) : null}
                      </StyledTableCell>
                    </StyledTableRow>
                  ))}
            </TableBody>
          </Table> */}

        <Table className={classes.table} aria-label="customized table">
          <TableFooter style={{ float: "right" }}>
            {!loaderToggle && (
              <Grid item xs={12} className={classes.notificationPagination}>
                <TableRow>
                  {noOfPages !== null && noOfPages !== undefined && (
                    <Pagination
                      count={noOfPages}
                      page={isFilterChanged ? filterPage : page}
                      onChange={handlePaginationChange}
                      defaultPage={1}
                      color="primary"
                      size="large"
                    />
                  )}
                </TableRow>
              </Grid>
            )}
          </TableFooter>
        </Table>
      </TableContainer>
      {/* ===================   close pop up======================== */}
      <div>
        <Dialog
          style={{ padding: "10px" }}
          fullWidth={true}
          open={openClose}
          onClose={() => setopenClose(false)}
          className={classes.dialog_padding}
          maxWidth="sm"
          aria-labelledby="responsive-dialog-title"
        >
          <div class="action-dialog_top">
            <DialogTitle
              id="alert-dialog-slide-title"
              class="action_dialog_title text-center"
            >
              Close Ticket
            </DialogTitle>
            <div class="action-dialog_icon" onClick={() => setopenClose(false)}>
              <IconButton aria-label="close" className={classes.closeButton}>
                <CloseIcon />
              </IconButton>
            </div>
          </div>

          <DialogContent className="user_form form_dealer">
            <Grid container spacing={3}>
              <Grid className="comman_label" container item xs={12} sm={2}>
                <label className="comman_label">Comment</label>
              </Grid>
              <Grid container className="comman_label" item xs={12} sm={10}>
                <TextareaAutosize
                  aria-label="minimum height"
                  cols={100}
                  rowsMin={5}
                  name="adminComment"
                  onChange={handleChangeClose}
                />
              </Grid>
              {validationsErr && validationsErr.errors.adminComment && (
                <div class="errMsgConatiner">
                  {validationsErr.errors.adminComment}
                </div>
              )}
            </Grid>
          </DialogContent>

          <DialogActions>
            <Button
              onClick={handleOnsubmitClose}
              variant="contained"
              size="medium"
              color="primary"
            >
              OK
            </Button>
            <Button
              onClick={() => {
                setopenClose(false);
              }}
              variant="contained"
              size="medium"
              color="secondary"
            >
              Cancel
            </Button>
          </DialogActions>
        </Dialog>
      </div>
      <div className={classes.loaderDiv}>
        {/* <Loader /> */}
        {loaderToggle ? (
          <Backdrop
            className={classes.backdrop}
            open={true}
            onClick={handleClose}
          >
            <CircularProgress color="inherit" />
          </Backdrop>
        ) : null}
      </div>{" "}
    </>
  );
}
