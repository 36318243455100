import React from "react";
import { Grid } from "@material-ui/core";
import Widget from "../../components/Widget";

export default function Bills() {
  return (
    <>
      <Grid style={{position: "relative",top: "60px"}} container spacing={4}>
       <Grid item xs={12}>
          <Widget title="Bills">
           <p> Comming Soon</p>
            {/* <Table 
             data={mock.table} /> */}
          </Widget>
        </Grid>
      </Grid>
    </>
  );
}
