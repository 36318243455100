


// export const API_BASE_URL = 'https://keycodecentro-development.herokuapp.com/api/v1';
export const API_BASE_URL = 'https://api.keycodecentro.net/api/v1';
export const FORGOT_PASSWORD = '/user/forget-password';
export const SIGN_OUT = '/auth/logout';
export const API_LOGIN_API = '/auth/login';
export const LOGIN_API = `${API_BASE_URL}${API_LOGIN_API}`;
export const FORGOT_PASS_API = `${API_BASE_URL}${FORGOT_PASSWORD}`;
export const SIGN_OUT_API = `${API_BASE_URL}${SIGN_OUT}`;

export const headers = {
    'Access-Control-Allow-Origin': '*',
    'Access-Control-Allow-Credentials': true,
    'Access-Control-Allow-Headers': 'Origin, X-Requested-With, Content-Type, Accept',
    'Access-Control-Allow-Methods': 'GET, POST, PUT, PATCH, DELETE, HEAD, OPTIONS',
    'Accept': 'text/html',
    'Content-Type': 'application/json',
    };