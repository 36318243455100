import { API_BASE_URL } from "../../apis/config";
import axios from "axios";

/** Get all notificaitons */
export const browseNotification = async (authtoken, pageNo) => {
  return await axios.get(`${API_BASE_URL}/notification?page=${pageNo}`, {
    headers: { "auth-token": authtoken },
  });
};

/** Update notification status */
export const updateNotification = async (authtoken, data) => {
  return await axios.put(`${API_BASE_URL}/notification`, data, {
    headers: { "auth-token": authtoken },
  });
};

/** Get notification count */
export const notificationsNumber = async (authtoken) => {
  return await axios.get(`${API_BASE_URL}/notification/new`,  {
    headers: { "auth-token": authtoken },
  });
};
