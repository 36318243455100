import { makeStyles } from "@material-ui/styles";


export default makeStyles(theme => ({
  container: {
    height: "100vh",
    width: "100vw",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    position: "absolute",
    top: 0,
    left: 0,
    
    
  },
  logotypeContainer: {
    backgroundColor: theme.palette.primary.main,
    width: "60%",
    height: "100%",
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "center",
    [theme.breakpoints.down("md")]: {
      width: "50%",
    },
    [theme.breakpoints.down("md")]: {
      display: "none",
    },
  },
  logotypeImage: {
    width: 165,
    marginBottom: theme.spacing(4),
  },
  logotypeText: {
    color: "white",
    fontWeight: 500,
    fontSize: 84,
    [theme.breakpoints.down("md")]: {
      fontSize: 48,
    },
  },
  formContainer: {
    width: "25%",
    height: "80%",
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "center",
    boxShadow: "0 0 20px rgba(0,0,0,.180392)",
    background: "#fff",
    borderRadius: ".25rem",
    [theme.breakpoints.down("md")]: {
      width: "50%",
    },
  },
  form: {
    width: 320,
  },
  tab: {
    fontWeight: 400,
    fontSize: 18,
  },
  greeting: {
    fontWeight: 500,
    textAlign: "center",
    marginTop: theme.spacing(4),
  },
  subGreeting: {
    fontWeight: 500,
    textAlign: "center",
    marginTop: theme.spacing(2),
  },
  googleButton: {
    // marginTop: theme.spacing(6),
    // boxShadow: theme.customShadows.widget,
    backgroundColor: "white",
    width: "100%",
    textTransform: "none",
  },
  googleButtonCreating: {
    marginTop: 0,
  },
  googleIcon: {
    width: "100%",
    height: "100px",
    // marginRight: theme.spacing(2),
  },
  creatingButtonContainer: {
    marginTop: theme.spacing(2.5),
    height: 46,
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
  },
  createAccountButton: {
    height: 46,
    textTransform: "none",
  },
  formDividerContainer: {
    marginTop: theme.spacing(4),
    marginBottom: theme.spacing(4),
    display: "flex",
    alignItems: "center",
  },
  formDividerWord: {
    paddingLeft: theme.spacing(2),
    paddingRight: theme.spacing(2),
  },
  formDivider: {
    flexGrow: 1,
    height: 1,
    backgroundColor: theme.palette.text.hint + "40",
  },
  errorMessage: {
    textAlign: "center",
    backgroundColor:"#e0abab",
    // width: "auto",
    fontSize: "17px",
    padding: "5px",
    // textAlign: "center",
    border: "2px solid #e46c82",
    color: "black",
  },
  // textFieldUnderline: {
  //   "&:before": {
  //     borderBottomColor: theme.palette.primary.light,
  //   },
  //   "&:after": {
  //     borderBottomColor: theme.palette.primary.main,
  //   },
  //   "&:hover:before": {
  //     borderBottomColor: `${theme.palette.primary.light} !important`,
  //   },
  // },
  textField: {
    // borderBottomColor: theme.palette.background.light,
    border: "1px solid black",
    borderRadius: "5px",
    height: "30px",
    textAlign: "center",
    color: "black",
  },

  inputEmail: {
    marginBottom: "20px",
    marginTop: "20px"
  },

  formButtons: {
    width: "100%",
    // marginTop: theme.spacing(4),
    display: "flex",
    justifyContent: "flex-end",
    alignItems: "center",
  },
  forgetButton: {
    textTransform: "none",
    fontWeight: 400,
  },
  loginLoader: {
    marginLeft: theme.spacing(4),
  },
  copyright: {
    marginTop: theme.spacing(4),
    whiteSpace: "nowrap",
    [theme.breakpoints.up("md")]: {
      position: "absolute",
      bottom: theme.spacing(2),
    },
  },

  errorTag: {
    backgroundColor:"#e0abab",
    width: "auto",
    fontSize: "20px",
    padding: "6px",
    textAlign: "center",
    border: "2px solid #e46c82",
    color: "black",
  },

  captchaTitle: {
    margin: "15px 5px",
    fontSize: "larger"
  },
  
  captchaDiv: {
    marginTop: "25px"
  },

  loginBtn : {
    marginTop: "15px",
    width: "50%",
    
  },

  

  loginBtnDiv : {
    textAlign: "center"
  }
}));
