import { API_BASE_URL } from "../../apis/config";
import axios from "axios";

/** Get All Request */
export const browseRequest = async (authtoken, data, pageNo) => {
  if (data !== "") {
    return await axios.get(`${API_BASE_URL}/keycode?status=${data}&page=${pageNo}`, {
      headers: { "auth-token": authtoken },
    });
  } else {
    return await axios.get(`${API_BASE_URL}/keycode/?page=${pageNo}`, {
      headers: { "auth-token": authtoken },
    });
  }

};

/** Add new Request */
export const addNewRequest = async (authtoken, data) => {
  return await axios.post(`${API_BASE_URL}/keycode/`, data, {
    headers: { "auth-token": authtoken },
  });
};

/** Get dealer's request */
export const getSingleRequestDealer = async (authtoken, id) => {
  return await axios.get(`${API_BASE_URL}/keycode/${id}`, {
    headers: { "auth-token": authtoken },
  });
};

/** Reveal Keycode */
export const revealKeycode = async (authtoken, id) => {
  let data = "";
  return await axios.put(`${API_BASE_URL}/keycode/reveal/${id}`, data, {
    headers: { "auth-token": authtoken },
  });
}

/** Refuse Keycode */
export const refuseKeycode = async (authtoken, data, id) => {

  return await axios.put(`${API_BASE_URL}/keycode/refuse/${id}`, data, {
    headers: { "auth-token": authtoken },
  });
}

/** Serve Keycode */
export const serveKeycode = async (authtoken, id, data) => {
  return await axios.put(`${API_BASE_URL}/keycode/serve/${id}`, data, {
    headers: { "auth-token": authtoken },
  });
}

/** Cancle Keycode */
export const cancelKeycode = async (authtoken, id) => {
  let data = "";
  return await axios.put(`${API_BASE_URL}/keycode/cancel/${id}`, data, {
    headers: { "auth-token": authtoken },
  });
}

/** Lock Keycode */
export const lockKeycode = async (authtoken, id) => {
  let data = "";
  return await axios.put(`${API_BASE_URL}/keycode/lock/${id}`, data, {
    headers: { "auth-token": authtoken },
  });
}

/** Unlock Keycode */
export const unLockKeycode = async (authtoken, id) => {
  let data = "";
  return await axios.put(`${API_BASE_URL}/keycode/unlock/${id}`, data, {
    headers: { "auth-token": authtoken },
  });
}

/** Get Single Dealer */
export const getSingleDealer = async (authtoken) => {
  return await axios.get(`${API_BASE_URL}/dealer`, {
    headers: { "auth-token": authtoken },
  });
};

/** Get Single User */
export const getSingleUser = async (authtoken) => {
  return await axios
    .get(
      `${API_BASE_URL}/user`, { headers: { "auth-token": authtoken } })
}