import React from 'react';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import CloseIcon from '@material-ui/icons/Close';

export default function AlertDialogSlide(props) {

  return (
    <div>
      <Dialog
        open={true}
        maxWidth="xs"
        aria-labelledby="responsive-dialog-title"
        className="alert_dialog"
        fullWidth={true}
      >
        <div className="action-dialog_top">
          <DialogTitle id="alert-dialog-slide-title" className="action_dialog_title">{props.content}</DialogTitle>
          <div
            className="action-dialog_icon"
            style={{ color: " rgba(0, 0, 0, 0.54)" }}
            onClick={() => props.handleClosepopupalert("cancel")}><CloseIcon />
          </div>
        </div>

        <DialogContent>
          <DialogContentText id="alert-dialog-slide-description">
            Are You Sure?
          </DialogContentText>
        </DialogContent>
        <DialogActions>
              <Button
                      onClick={()=>props.handleClosepopupalert("ok")}
                      variant="contained"
                      size="medium"
                      color="primary">
                      OK
               </Button>
                <Button
                      onClick={()=>props.handleClosepopupalert("cancel")}
                      variant="contained"
                      size="medium"
                      color="secondary">
                      Cancel
                </Button>
              </DialogActions>
      </Dialog>
    </div>
  );
}