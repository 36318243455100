import React from "react";
import {
  Grid,

  
  Button,
 
} from "@material-ui/core";
import { withRouter, Link  } from "react-router-dom";

// styles
import useStyles from "./styles";

import corporateLogo from "../../images/logo_placeholder-square.png";

// context
import {  useUserState } from "../../context/UserContext";


function Thankyou(props) {
  var classes = useStyles();

  // global
  var { successRes } = useUserState();
  

    
  return (
    <Grid container className={classes.container}>
     
      <div className={classes.formContainer}>
        <div className={classes.form}>
          
         
            <React.Fragment>
             
              <Button size="large" className={classes.googleButton}>
                <img src={corporateLogo} alt="Login" className={classes.googleIcon} />
                {/* &nbsp;Sign in with Google */}
              </Button> 
              
              
              <div className={classes.formButtons}>
                
              </div>
            </React.Fragment>
         
          <div className={classes.msg}>
        <span className={classes.captchaTitle}>Thanks <br/>
{successRes.successmsg}</span>
</div>
       
        </div>
        <div className={classes.msg}>
        <Link className={classes.msganchor} to="/">back to login screen</Link>
        </div>
      </div>
      
    </Grid>
  );
}

export default withRouter(Thankyou);
