import React, { useEffect, useState } from "react";
import {
  Grid,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  TableFooter,
} from "@material-ui/core";
// styles
import "react-toastify/dist/ReactToastify.css";
import useStyles from "./styles";
import { withStyles } from "@material-ui/core/styles";
// components
import PageTitle from "../../components/PageTitle/PageTitle";
import { Typography } from "../../components/Wrappers/Wrappers";
import {
  browseNotification,
  updateNotification,
  notificationsNumber,
} from "./Notificationsapis";
import Backdrop from "@material-ui/core/Backdrop";
import CircularProgress from "@material-ui/core/CircularProgress";
import Pagination from "@material-ui/lab/Pagination";
import {
  useLayoutDispatch,
  setGlobalOpenedRow,
} from "../../context/LayoutContext";

const StyledTableCell = withStyles((theme) => ({
  head: {
    backgroundColor: theme.palette.common.white,
  },
  body: {
    fontSize: 14,
  },
}))(TableCell);

const StyledTableRow = withStyles((theme) => ({
  root: {
    "&:nth-of-type(odd)": {
      backgroundColor: theme.palette.common.white,
    },
  },
}))(TableRow);

export default function NotificationsPage(props) {
  let layoutDispatch = useLayoutDispatch();
  const [allNotificationslist, setAllNotificationslist] = useState([]);
  const [loaderToggle, setLoaderToggle] = useState(true);
  const [page, setPage] = React.useState(1);
  const [noOfPages, setNoOfPages] = useState();
  const [mainError, setMainError] = useState("");
  var classes = useStyles();
  const itemsPerPage = 10;

  useEffect(() => {
    setLoaderToggle(true);
    /** Browse Notification API*/
    const allNotification = browseNotification(
      localStorage.getItem("jwtauthtoken"),
      page,
    );
    allNotification
      .then((response) => {
        if (response.data) {
          setAllNotificationslist(response.data.data.notifications);
          setLoaderToggle(false);
          setNoOfPages(
            Math.ceil(response.data.pagination.total / itemsPerPage),
          );
          let notificationArr = [];
          response.data.data.notifications.slice(0, 10).map((item, index) => {
            return notificationArr.push(item._id);
          });
          const updateNotificationsApi = (dataArr) => {
            let dataPayload = { notifications: dataArr };
            const allNotification = updateNotification(
              localStorage.getItem("jwtauthtoken"),
              dataPayload,
            );
            allNotification
              .then((response) => {
                setLoaderToggle(false);
                const notificationsNo = notificationsNumber(
                  localStorage.getItem("jwtauthtoken"),
                );
                notificationsNo
                  .then((response) => {
                    // setAllNotifications(response.data.data);
                    setGlobalOpenedRow(layoutDispatch, response.data.data);
                  })
                  .catch((error) => {});
              })
              .catch((error) => {
                setLoaderToggle(false);
                setMainError(error.response.data.message);
              });
          };
          updateNotificationsApi(notificationArr);
        }
      })
      .catch((error) => {
        error.response && setMainError(error.response.data.message);
        setLoaderToggle(false);
      });
  }, [page]);

  /** UPdate Notification API*/
  const updateNotificationsApi = (dataArr) => {
    let dataPayload = { notifications: dataArr };
    const allNotification = updateNotification(
      localStorage.getItem("jwtauthtoken"),
      dataPayload,
    );
    allNotification
      .then((response) => {
        setLoaderToggle(false);
        const notificationsNo = notificationsNumber(
          localStorage.getItem("jwtauthtoken"),
        );
        notificationsNo
          .then((response) => {
            // setAllNotifications(response.data.data);
            setGlobalOpenedRow(layoutDispatch, response.data.data);
          })
          .catch((error) => {});
      })
      .catch((error) => {
        setLoaderToggle(false);
        setMainError(error.response.data.message);
      });
  };
  /** Pagination*/
  const handleNotificationArr = (value) => {
    let notificationArr = [];
    noOfPages !== undefined &&
      allNotificationslist
        .slice((value - 1) * itemsPerPage, value * itemsPerPage)
        .map((item, index) => {
          return notificationArr.push(item._id);
        });
    updateNotificationsApi(notificationArr);
  };
  /** Pagination*/
  const handlePaginationChange = (event, value) => {
    setPage(value);
    handleNotificationArr(value);
  };

  return (
    <>
      <PageTitle title="Notifications" />
      <Grid container spacing={4}>
        <Grid item xs={12}>
          <div className={classes.root}>
            {mainError && mainError !== "" && (
              <Grid classes={{ root: classes.paperRoot }}>
                <Typography
                  variant="h5"
                  color="primary"
                  className={classes.textRow}
                >
                  {mainError}
                </Typography>
              </Grid>
            )}

            <TableContainer component={Paper}>
              <Table className={classes.table} aria-label="customized table">
                <TableHead>
                  <TableRow className={classes.tableHeadings}>
                    <StyledTableCell align="center">Subject</StyledTableCell>
                    <StyledTableCell align="center">Message</StyledTableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {allNotificationslist.length === 0 && (
                    <StyledTableRow>
                      <StyledTableCell colSpan="12" align="center">
                        No Records Found
                      </StyledTableCell>
                    </StyledTableRow>
                  )}
                  {noOfPages !== undefined &&
                    allNotificationslist
                      .slice(0, itemsPerPage)
                      .map((item, index) => (
                        <StyledTableRow>
                          <StyledTableCell align="center">
                            {item.notification.subject}
                          </StyledTableCell>
                          <StyledTableCell align="center">
                            {item.notification.message}
                          </StyledTableCell>
                        </StyledTableRow>
                      ))}
                </TableBody>
              </Table>
              <Table className={classes.table} aria-label="customized table">
                {allNotificationslist.length > 0 && (
                  <TableFooter style={{ float: "right" }}>
                    {!loaderToggle && (
                      <Grid
                        item
                        xs={12}
                        className={classes.notificationPagination}
                      >
                        <TableRow>
                          {noOfPages !== null && noOfPages !== undefined && (
                            <Pagination
                              count={noOfPages}
                              page={page}
                              onChange={handlePaginationChange}
                              defaultPage={1}
                              color="primary"
                              size="large"
                            />
                          )}
                        </TableRow>
                      </Grid>
                    )}
                  </TableFooter>
                )}
              </Table>
            </TableContainer>
          </div>
        </Grid>
      </Grid>
      <div className={classes.loaderDiv}>
        {/* <Loader /> */}
        {loaderToggle ? (
          <Backdrop className={classes.backdrop} open={true}>
            <CircularProgress color="inherit" />
          </Backdrop>
        ) : null}
      </div>
    </>
  );
}
