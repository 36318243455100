import React, { useEffect, useState, useRef } from "react";
import {
  Grid,
  CircularProgress,
  Typography,
  Button,
  TextField,
  Fade,
} from "@material-ui/core";
import "./Activate.css";
import { withRouter, useHistory } from "react-router-dom";
import EthCrypto from "eth-crypto";
import useStyles from "./styles";
import ReCAPTCHA from "react-google-recaptcha";
import { activateUser } from "../Users/Usersapis";

const TEST_SITE_KEY = "6LeNOhoaAAAAACOn2KH2Ey6orwqI7FTOrREnP88I";
const DELAY = 1000;

function Activate(props) {
  var classes = useStyles();
  let history = useHistory();
  var [isLoading, setIsLoading] = useState(false);
  var [errorEmail, setErrorEmail] = useState("");
  var [errorPassword, setErrorPassword] = useState("");
  var [errorConfirmPassword, setErrorConfirmPassword] = useState("");
  var [errorCapcha, setErrorCapcha] = useState(0);
  var [loginValue, setLoginValue] = useState("");
  var [passwordValue, setPasswordValue] = useState("");
  var [confirmPasswordValue, setConfirmPasswordValue] = useState("");
  const [captchaValue, setCaptchaValue] = useState("");
  const [mainError, setMainError] = useState("");
  const [load, setLoad] = useState(false);
  const [activationToken, setActivationToken] = useState("");
  const _reCaptchaRef = useRef();

  useEffect(() => {
    setTimeout(() => {
      setLoad(true);
    }, DELAY);

    setActivationToken(props.match.params.id);
  }, [props.match.params.id]);

  /** Captcha*/
  const handleChangeCaptcha = (value) => {
    setErrorCapcha("");
    setCaptchaValue(value);
  };

  /** Change Email*/
  const HandleChangeEmail = (event) => {
    setLoginValue(event.target.value);
    setErrorEmail("");
    setMainError("");
  };

  /** Change password*/
  const HandleChangePassword = (event) => {
    setPasswordValue(event.target.value);
    setErrorPassword("");
    setMainError("");
  };

  /** Check confirm password*/
  const HandleChangeConfirmPassword = (event) => {
    setConfirmPasswordValue(event.target.value);
    setErrorConfirmPassword("");
    setMainError("");
  };

  /** Activate user api call and form validation*/
  const handleFormSubmit = () => {
    let strongPassword = new RegExp("^(?=.*[0-9])" + "(?=.*[a-z])(?=.*[A-Z])" + "(?=.*[@#$%^&+=])" + "(?=\\S+$).{8,}$");
    setIsLoading(true);
    if (loginValue === "") {
      setErrorEmail("Please Enter Your Email");
      setIsLoading(false);
    } else if (!/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(loginValue)) {
      setErrorEmail("Must be an email");
      setIsLoading(false);
    } else if (passwordValue === "") {
      setErrorPassword("Please Enter Password");
      setIsLoading(false);
    }else  
     if(!strongPassword.test(passwordValue)) {
      setErrorPassword("Password not matching minimum criteria, Password should be atleast 8 alphanumeric and contains small letters, capital letters and symbols");
      setIsLoading(false);
    } else if (confirmPasswordValue === "") {
      setErrorConfirmPassword("Please Enter Confirm Password");
      setIsLoading(false);
    } else if (passwordValue !== confirmPasswordValue) {
      setErrorConfirmPassword("Password and confirm password does not match");
      setIsLoading(false);
    } else if (captchaValue === "") {
      setErrorCapcha("Please Select Captcha");
      setIsLoading(false);
      return false;
    } else {
      const hashedPasswords = EthCrypto.hash.keccak256(passwordValue);
      const secret = hashedPasswords + hashedPasswords;
      const { publicKey } = EthCrypto.createIdentity(
        Buffer.from(secret, "utf-8"),
      );
      let AllData = {
        email: loginValue,
        hashedPassword: hashedPasswords,
        publicKey: publicKey,
      };
      const activateUserData = activateUser(activationToken, AllData);
      activateUserData
        .then((response) => {
          setIsLoading(false);
          if (response.data.success) {
            history.push("/login");
            setErrorEmail("");
            setErrorEmail("");
            setErrorPassword("");
            setErrorConfirmPassword("");
            setErrorCapcha("");
          }
        })
        .catch((error) => {
          error.response.data && setMainError(error.response.data.errors);
          setErrorEmail("");
          setErrorEmail("");
          setErrorPassword("");
          setErrorConfirmPassword("");
          setErrorCapcha("");
          setIsLoading(false);
        });
    }
  };

  return (
    <Grid container className={classes.container}>
      <div className={classes.formContainer}>
        <div className={classes.form}>
          <React.Fragment>
            {mainError &&
              mainError.length > 0 &&
              mainError.map((item) => (
                <>
                  <Fade in={mainError}>
                    <Typography
                      color="secondary"
                      style={{ marinBottom: "8px" }}
                      className={classes.errorMessage}
                    >
                      {item.msg}
                    </Typography>
                  </Fade>
                </>
              ))}

            <TextField
              id="email"
              InputProps={{
                classes: {
                  underline: classes.textFieldUnderline,
                  input: classes.textField,
                },
              }}
              value={loginValue}
              onChange={(e) => HandleChangeEmail(e)}
              margin="normal"
              placeholder="Email"
              type="email"
              fullWidth={true}
            />
            {errorEmail && errorEmail && (
              <Fade in={errorEmail}>
                <Typography color="secondary" className={classes.errorMessage}>
                  {errorEmail}
                </Typography>
              </Fade>
            )}
            <TextField
              id="password"
              InputProps={{
                classes: {
                  underline: classes.textFieldUnderline,
                  input: classes.textField,
                },
              }}
              value={passwordValue}
              onChange={(e) => HandleChangePassword(e)}
              margin="normal"
              placeholder="Password"
              type="password"
              fullWidth={true}
            />
            {errorPassword && errorPassword ? (
              <Fade in={errorPassword}>
                <Typography color="secondary" className={classes.errorMessage}>
                  {errorPassword}
                </Typography>
              </Fade>
            ) : null}
            <TextField
              id="confirmPassword"
              InputProps={{
                classes: {
                  underline: classes.textFieldUnderline,
                  input: classes.textField,
                },
              }}
              value={confirmPasswordValue}
              onChange={(e) => HandleChangeConfirmPassword(e)}
              margin="normal"
              placeholder="Confirm Password"
              type="password"
              fullWidth={true}
            />
            {errorConfirmPassword && errorConfirmPassword ? (
              <Fade in={errorConfirmPassword}>
                <Typography color="secondary" className={classes.errorMessage}>
                  {errorConfirmPassword}
                </Typography>
              </Fade>
            ) : null}
          </React.Fragment>

          <span className={classes.captchaTitle}>
            Please check the box below to proceed.
          </span>
          <div className={classes.captchaDiv}>
            {load && (
              <ReCAPTCHA
                style={{ display: "inline-block" }}
                theme="light"
                ref={_reCaptchaRef}
                sitekey={TEST_SITE_KEY}
                onChange={handleChangeCaptcha}
              />
            )}
          </div>
          {errorCapcha && errorCapcha ? (
            <Fade in={errorCapcha}>
              <Typography color="secondary" className={classes.errorMessage}>
                {errorCapcha}
              </Typography>
            </Fade>
          ) : null}
          <div className={classes.loginBtnDiv}>
            <Button
              className={classes.loginBtn}
              onClick={() => handleFormSubmit()}
              variant="contained"
              color="primary"
              size="large"
            >
              Ok{" "}
              {isLoading ? (
                <CircularProgress size={26} className={classes.loginLoader} />
              ) : null}
            </Button>
          </div>
        </div>
      </div>
    </Grid>
  );
}

export default withRouter(Activate);
