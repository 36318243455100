import React, { useEffect, useState } from "react";
import "../Dealer/Dealer.css";
import Box from "@material-ui/core/Box";
import Collapse from "@material-ui/core/Collapse";
import IconButton from "@material-ui/core/IconButton";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import { Chip } from "@material-ui/core";
import Paper from "@material-ui/core/Paper";
import KeyboardArrowDownIcon from "@material-ui/icons/KeyboardArrowDown";
import KeyboardArrowUpIcon from "@material-ui/icons/KeyboardArrowUp";
import CloseIcon from "@material-ui/icons/Close";
import {
  browseDealers,
  deleteExistingDealer,
  suspendDealers,
  resumeDealers,
  suspendDealerUser,
  resumeDealerUser,
  editSingleDealer,
  getSingleDealer,
  addNewDealer,
  deleteExistingUser,
  getAllCountry,
  getAllCityFromCountry,
} from "./Dealerapis";
import Radio from "@material-ui/core/Radio";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import RadioGroup from "@material-ui/core/RadioGroup";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogTitle from "@material-ui/core/DialogTitle";
import Grid from "@material-ui/core/Grid";
import useStyles from "./styles";
import AlertDialogSlide from "../alertpopup/AlertDialogSlide";
import { Button } from "../../components/Wrappers/Wrappers";
import classnames from "classnames";
import {
  useUserDispatch,
  setGlobalSuccessMsg,
  setGlobalErrMsg,
} from "../../context/UserContext";
import Alert from "@material-ui/lab/Alert";
import Backdrop from "@material-ui/core/Backdrop";
import CircularProgress from "@material-ui/core/CircularProgress";

export default function Dealer(props) {
  var classes = useStyles();
  var userDispatch = useUserDispatch();
  const [allDealerData, setAllDealerData] = useState([]);
  const [loadComponent, setLoadComponent] = useState([]);
  const [alertBox, setAlertBox] = useState(false);
  const [suspendid, setssuspendid] = useState("");
  const [alertStatus, setalertStatus] = useState("");
  const [editSingleRes, setEditSingleRes] = useState([]);
  const [editSingleResData, setEditSingleResData] = useState({});
  const [openEdit, setopenEdit] = useState(false);
  const authtoken = localStorage.getItem("jwtauthtoken");
  const [allFomrData, setallFomrData] = useState({
    name: "",
    country: "",
    city: "",
    address: "",
    keycode_cost: "",
    dealer_type: "",
  });
  const [open, setOpen] = React.useState(false);
  const [loaderToggle, setLoaderToggle] = useState(true);
  const [allCountrylist, setAllCountrylist] = useState([]);
  const [allCitylist, setAllCitylist] = useState([]);
  const [allEditCitylist, setAllEditCitylist] = useState([]);
  const [openrow, setOpenrow] = React.useState(false);
  const [clickedrow, setClickedrow] = React.useState("");
  const [validationsErr, setValidationsErr] = useState("");
  const [visible, setVisible] = useState(false);
  const [respoMsg, setRespoMsg] = useState("");
  let userdetails = JSON.parse(localStorage.getItem("userdetails"));
  let userrole = userdetails.role;

  useEffect(() => {
    setLoaderToggle(true);

    /** Browse Dealer data API*/
    const browseDealerData = browseDealers(
      localStorage.getItem("jwtauthtoken"),
    );
    browseDealerData
      .then((response) => {
        setLoaderToggle(false);
        setAllDealerData(response.data.data.dealers);
      })
      .catch((error) => {
        setLoaderToggle(false);
      });

    /** Country list API*/
    const allCountry = getAllCountry();
    allCountry
      .then((response) => {
        setAllCountrylist(response.data.data.countries);
      })
      .catch((error) => {
        setLoaderToggle(false);
      });
  }, [loadComponent]);

  useEffect(() => {
    const timer = setTimeout(() => {
      setVisible(false);
    }, 10000);
    return () => clearTimeout(timer);
  }, [visible]);

  /** Suspend Dealer */
  const HandleSuspend = (id, status) => {
    setAlertBox(true);
    setssuspendid(id);
    setalertStatus(
      status === "suspended" ? "Resume a Dealer" : "Suspend a Dealer",
    );
  };

  /** Modal for Suspend Dealer & User, Resume Dealer & User,Delete Dealer & User API*/
  const handleClosepopup = (value) => {
    if (value === "ok" && alertStatus === "Suspend a Dealer") {
      const suspendDealer = suspendDealers(authtoken, suspendid);
      suspendDealer
        .then((response) => {
          if (response.data.success === true) {
            setGlobalSuccessMsg(
              "Dealer has been successfully Suspended",
              userDispatch,
              "success",
            );
            setLoadComponent(response.data.data.dealer);
          }
        })
        .catch((error) => {
          error.response &&
            setGlobalErrMsg(error.response.data.errors[0].msg, userDispatch);
        });
    } else if (value === "ok" && alertStatus === "Resume a Dealer") {
      /** Resume Dealer API*/
      const resumeDealer = resumeDealers(authtoken, suspendid);
      resumeDealer
        .then((response) => {
          if (response.data.success === true) {
            setGlobalSuccessMsg(
              "Dealer has been successfully Resumed",
              userDispatch,
              "success",
            );
            setLoadComponent(response.data.data.dealer);
          }
        })
        .catch((error) => {
          error.response &&
            setGlobalSuccessMsg(
              error.response.data.message,
              userDispatch,
              "error",
            );
        });
    } else if (value === "ok" && alertStatus === "Delete a Dealer") {
      /** Delete Dealer API */
      const deleteRes = deleteExistingDealer(authtoken, suspendid);
      deleteRes
        .then((response) => {
          if (response.data.success === true) {
            setGlobalSuccessMsg(
              "Dealer has been successfully Deleted",
              userDispatch,
              "success",
            );
            setLoadComponent(response.data.data.dealer);
          }
        })
        .catch((error) => {
          error.response &&
            setGlobalSuccessMsg(
              error.response.data.errors[0].msg,
              userDispatch,
              "error",
            );
        });
    } else if (value === "ok" && alertStatus === "Delete a User") {
      /** Delete User API */
      const deleteUserRes = deleteExistingUser(authtoken, suspendid);
      deleteUserRes
        .then((response) => {
          setGlobalSuccessMsg(response.data.message, userDispatch, "success");
          setLoadComponent(response);
        })
        .catch((error) => {
          error.response &&
            setGlobalSuccessMsg(
              error.response.data.message,
              userDispatch,
              "error",
            );
        });
    } else if (value === "ok" && alertStatus === "Suspend a User") {
      /** Suspend User API */
      const suspendUser = suspendDealerUser(authtoken, suspendid);
      suspendUser
        .then((response) => {
          setRespoMsg({ data: response.data.message, satus: "success" });
          setVisible(true);
        })
        .catch((error) => {
          error.response &&
            setRespoMsg({
              data: error.response.data.errorMessage,
              satus: "error",
            });
          setVisible(true);
        });
    } else if (value === "ok" && alertStatus === "Resume a User") {
      /** Resume User API */
      const resumeUser = resumeDealerUser(authtoken, suspendid);
      resumeUser
        .then((response) => {
          setRespoMsg({ data: response.data.message, satus: "success" });
          setVisible(true);
        })
        .catch((error) => {
          error.response &&
            setRespoMsg({ data: error.response.data.message, satus: "error" });
          setVisible(true);
        });
    }
    setAlertBox(false);
  };
  /** Delete Dealer**/
  const HandleDelete = (id) => {
    setAlertBox(true);
    setssuspendid(id);
    setalertStatus("Delete a Dealer");
  };
  /** Modal to Resume & Suspend User**/
  const HandleSubSuspend = (id, status, rowid) => {
    setAlertBox(true);
    setssuspendid(id);
    setalertStatus(status === "suspended" ? "Resume a User" : "Suspend a User");
  };
  /** Delete User**/
  const HandleSubDelete = (id) => {
    setAlertBox(true);
    setssuspendid(id);
    setalertStatus("Delete a User");
  };
  /** Edit DealerForm API**/
  const HandleEdit = (id) => {
    setValidationsErr({ errors: [] });
    const singleDealer = getSingleDealer(authtoken, id);
    singleDealer
      .then((response) => {
        setEditSingleRes(response.data.data.dealer);
        setopenEdit(true);
        setLoadComponent(response);
      })
      .catch((error) => {});
  };

  const handleClickOpen = () => {
    setOpen(true);
    setValidationsErr({ errors: [] });
  };

  const handleClose = () => {
    setOpen(false);
  };
  /** Add New Dealer API**/
  const handleOnsubmit = () => {
    if (handleValidation()) {
      const addDealer = addNewDealer(authtoken, allFomrData);
      addDealer
        .then((response) => {
          setLoadComponent(response);
          setGlobalSuccessMsg(
            "Dealer has been successfully Added",
            userDispatch,
            "success",
          );
          setOpen(false);
        })
        .catch((error) => {
          let errors = {};
          error.response.data.errors &&
            error.response.data.errors.map((item) => {
              return (errors[item.param] = item.msg);
            });
          errors && Object.entries(errors).length > 0
            ? setValidationsErr({ errors: errors })
            : error.response &&
              setGlobalSuccessMsg(
                error.response.data.message,
                userDispatch,
                "error",
              );
          setValidationsErr({ errors: errors });
        });
    } else {
      setOpen(true);
    }
  };
  /** Get Cities From Country API*/
  const handleChange = (event) => {
    if (event.target.name === "country") {
      var item = allCountrylist.find(
        (item) => item.name === event.target.value,
      );
      const citylist = getAllCityFromCountry(item.id);
      citylist
        .then((response) => {
          setAllCitylist(response.data.data.cities);
        })
        .catch((error) => {});
    }
    setallFomrData({ ...allFomrData, [event.target.name]: event.target.value });
  };
  /** set All Edit Citylist*/
  const handleChangeEdit = (event) => {
    if (event.target.name === "country") {
      var item = allCountrylist.find(
        (item) => item.name === event.target.value,
      );
      const citylist = getAllCityFromCountry(item.id);
      citylist
        .then((response) => {
          setAllEditCitylist(response.data.data.cities);
        })
        .catch((error) => {});
    }
    setEditSingleRes({
      ...editSingleRes,
      [event.target.name]: event.target.value,
    });
    setEditSingleResData({
      ...editSingleResData,
      [event.target.name]: event.target.value,
    });
  };
  /** Update Dealer API*/
  const handleOnsubmitEdit = () => {
    if (handleEditValidation()) {
      const editDealer = editSingleDealer(
        authtoken,
        editSingleRes._id,
        editSingleResData,
      );
      editDealer
        .then((response) => {
          setGlobalSuccessMsg(
            "Dealer has been successfully Update",
            userDispatch,
            "success",
          );
          setLoadComponent(response);
          setopenEdit(false);
        })
        .catch((error) => {
          error.response &&
            setGlobalSuccessMsg(
              error.response.data.errors[0].msg,
              userDispatch,
              "error",
            );
        });
    } else {
      setopenEdit(true);
    }
  };

  const handlerowclick = (id) => {
    setClickedrow(id);
    setOpenrow(!openrow);
  };
  /** Validation of Form*/
  const handleValidation = () => {
    let fields = allFomrData;
    let errors = {};
    let formIsValid = true;

    if (!fields["name"]) {
      formIsValid = false;
      errors["name"] = "Name can not be empty";
    }
    if (!fields["country"]) {
      formIsValid = false;
      errors["country"] = "Please select the country";
    }
    if (!fields["city"]) {
      formIsValid = false;
      errors["city"] = "Please select the city";
    }
    if (!fields["address"]) {
      formIsValid = false;
      errors["address"] = "address can not be empty";
    }
    if (!fields["dealer_type"]) {
      formIsValid = false;
      errors["dealer_type"] = "select the type";
    }
    if (!fields["keycode_cost"]) {
      formIsValid = false;
      errors["keycode_cost"] = "keycode cost can not be empty";
    }
    setValidationsErr({ errors: errors });
    return formIsValid;
  };
  /** Validation of Edit Form*/
  const handleEditValidation = () => {
    let fields = editSingleResData;
    let errors = {};
    let formIsValid = true;

    if (fields["name"] === "") {
      formIsValid = false;
      errors["name"] = "Name can not be empty";
    }
    if (fields["country"] === "") {
      formIsValid = false;
      errors["country"] = "Please select the country";
    }
    if (fields["city"] === "") {
      formIsValid = false;
      errors["city"] = "Please select the city";
    }
    if (fields["address"] === "") {
      formIsValid = false;
      errors["address"] = "address can not be empty";
    }
    if (fields["dealer_type"] === "") {
      formIsValid = false;
      errors["dealer_type"] = "select the type";
    }
    if (fields["keycode_cost"] === "") {
      formIsValid = false;
      errors["keycode_cost"] = "keycode cost can not be empty";
    }
    setValidationsErr({ errors: errors });
    return formIsValid;
  };
  /** Dealer Table*/
  const Row = (props) => {
    const { row } = props;
  
    return (
      <React.Fragment>
        
        <TableRow>
          <TableCell align="center" component="th" scope="row">
            {row.name}
          </TableCell>
          <TableCell align="center">{row.country}</TableCell>
          <TableCell align="center">{row.city}</TableCell>
          <TableCell align="center">{row.dealer_type}</TableCell>
          <TableCell align="center">${row.keycode_cost}</TableCell>
          <TableCell
            align="center"
            className={
              row.status === "suspended"
                ? classes.rowsuspended
                : classes.rowresume
            }
          >
            <Chip
              className={classes.chipPosittion}
              label={row.status}
              classes={{
                root:
                  classes[
                    row.status === "deActivated"
                      ? "secondary"
                      : row.status === "pending"
                      ? "warning"
                      : row.status === "active"
                      ? "success"
                      : row.status === "suspended"
                      ? "info"
                      : "success"
                  ],
              }}
            />
          </TableCell>
        
            

          {userrole !== "corporate" ? (
            <TableCell align="center" className="action_data">
              <div className={classes.buttonsContainer}>
                {row.status === "suspended" ? (
                  <Button
                    variant="contained"
                    color="primary"
                    style={{ marginRight: "10px" }}
                    className={classnames(classes.notificationCallButton)}
                    onClick={() => HandleSuspend(row._id, row.status)}
                  >
                    Resume
                  </Button>
                ) : (
                  <Button
                    variant="contained"
                    color="info"
                    style={{ marginRight: "10px" }}
                    className={classnames(classes.notificationCallButton)}
                    onClick={() => HandleSuspend(row._id, row.status)}
                  >
                    Suspend
                  </Button>
                )}
                <Button
                  variant="contained"
                  color="secondary"
                  style={{ marginRight: "10px" }}
                  className={classnames(classes.notificationCallButton)}
                  onClick={() => HandleDelete(row._id)}
                >
                  Delete
                </Button>
                <Button
                  variant="contained"
                  color="success"
                  className={classnames(classes.notificationCallButton)}
                  onClick={() => HandleEdit(row._id)}
                >
                  Edit
                </Button>
              </div>
            </TableCell>
          ) : null}

          {row.users && row.users.length > 0 ? (
            <TableCell>
              <IconButton
                aria-label="expand row"
                size="small"
                onClick={() => handlerowclick(row._id)}
              >
                {openrow ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
              </IconButton>
            </TableCell>
          ) : (
            <TableCell> </TableCell>
          )}
        </TableRow>

        <TableRow style={{ height: "auto" }}>
          <TableCell style={{ paddingBottom: 0, paddingTop: 0 }} colSpan={9}>
            {clickedrow === row._id && (
              <Collapse in={openrow} timeout="auto" unmountOnExit>
                <Box margin={1}>
                  {visible && respoMsg !== "" && (
                    <Grid
                      container
                      className={classes.successMsgContainer}
                      item
                      xs={12}
                      spacing={12}
                      sm={12}
                    >
                      <Alert
                        onClose={() => {
                          setVisible(false);
                        }}
                        className={classes.alertMsgBox}
                        severity={`${
                          respoMsg.status === "error" ? "error" : "success"
                        }`}
                      >
                        {respoMsg.data}
                      </Alert>
                    </Grid>
                  )}
                  <Table size="small" aria-label="purchases">
                    <TableHead className="Dealer_down_Table">
                      <TableRow>
                        <TableCell align="center">Name</TableCell>
                        <TableCell align="center">Email</TableCell>
                        <TableCell align="center">Mobile</TableCell>
                        <TableCell align="center">Status</TableCell>
                        {userrole !== "corporate" ? (
                          <TableCell align="center">Actions</TableCell>
                        ) : null}
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      {row.users &&
                        row.users.map((historyRow) => (
                          <TableRow key={historyRow.name}>
                            <TableCell
                              align="center"
                              component="th"
                              scope="row"
                            >
                              {historyRow.name}
                            </TableCell>
                            <TableCell align="center">
                              {historyRow.email}
                            </TableCell>
                            <TableCell align="center">
                              {historyRow.phone}
                            </TableCell>
                            <TableCell>
                              <Chip
                                className={classes.chipPosittion}
                                label={historyRow.status}
                                classes={{
                                  root:
                                    classes[
                                      historyRow.status === "suspended"
                                        ? "secondary"
                                        : historyRow.status === "resume"
                                        ? "success"
                                        : historyRow.status === "active"
                                        ? "success"
                                        : "secondary"
                                    ],
                                }}
                              />
                            </TableCell>
                            {historyRow.status === "deActivated" && (
                              <TableCell></TableCell>
                            )}
                            {userrole !== "corporate" &&
                            historyRow.status !== "deActivated" ? (
                              <TableCell className="downtbl_action">
                                {row.status !== "suspended" ? (
                                  <span
                                    className={
                                      row.status === "suspended"
                                        ? classes.suspended
                                        : classes.resume
                                    }
                                    onClick={() =>
                                      HandleSubSuspend(
                                        historyRow._id,
                                        historyRow.status,
                                        row._id,
                                      )
                                    }
                                  >
                                    <span
                                      className={
                                        historyRow.status === "suspended"
                                          ? classes.rowresume
                                          : classes.rowsuspended
                                      }
                                    >
                                      {" "}
                                      {historyRow.status === "suspended" ? (
                                        <Button
                                          variant="contained"
                                          color="primary"
                                          className={classnames(
                                            classes.notificationCallButton,
                                          )}
                                          style={{ marginRight: "10px" }}
                                        >
                                          Resume
                                        </Button>
                                      ) : (
                                        <Button
                                          variant="contained"
                                          color="info"
                                          style={{ marginRight: "10px" }}
                                          className={classnames(
                                            classes.notificationCallButton,
                                          )}
                                        >
                                          Suspend
                                        </Button>
                                      )}
                                    </span>
                                  </span>
                                ) : null}

                                {row.status === "suspended" ? (
                                  ""
                                ) : (
                                  <Button
                                    variant="contained"
                                    color="secondary"
                                    className={classnames(
                                      classes.notificationCallButton,
                                    )}
                                    onClick={() =>
                                      HandleSubDelete(historyRow._id)
                                    }
                                  >
                                    Delete
                                  </Button>
                                )}
                              </TableCell>
                            ) : null}
                          </TableRow>
                        ))}
                    </TableBody>
                  </Table>
                </Box>
              </Collapse>
            )}
          </TableCell>
        </TableRow>
      </React.Fragment>
    );
  };

  return (
    <div>
      <div className="user_button">
        <Grid
          container
          direction="row"
          justify="flex-end"
          alignItems="center"
          className="filterContainer"
        >
          {userrole === "admin" && (
            <Button
              variant="contained"
              size="medium"
              color="success"
              onClick={handleClickOpen}
            >
              New Dealer
            </Button>
          )}
        </Grid>
      </div>

      {/*-------------------------- add dealer popup---------------------  */}

      <Grid>
        <Dialog
          style={{ padding: "10px" }}
          fullWidth={true}
          open={open}
          onClose={() => setOpen(false)}
          className={classes.dialog_padding}
          maxWidth="sm"
          aria-labelledby="responsive-dialog-title"
        >
          <div className="action-dialog_top">
            <DialogTitle
              id="alert-dialog-slide-title"
              className="action_dialog_title text-center"
            >
              Add Dealer
            </DialogTitle>
            <div className="action-dialog_icon" onClick={() => setOpen(false)}>
              <IconButton aria-label="close" className={classes.closeButton}>
                <CloseIcon />
              </IconButton>
            </div>
          </div>

          <DialogContent className="form_dealer">
            <Grid container spacing={3}>
              <Grid className="label_responsive" container item xs={12} sm={3}>
                <label className="comman_label">Name</label>
              </Grid>
              <Grid className="label_responsive" container item xs={12} sm={9}>
                <input
                  type="text"
                  className="input_type"
                  name="name"
                  onChange={handleChange}
                  placeholder="Name"
                ></input>
                {validationsErr && validationsErr.errors.name && (
                  <div class="errMsgConatiner">
                    {validationsErr.errors.name}
                  </div>
                )}
              </Grid>

              <Grid
                className="label_responsive_country"
                container
                item
                xs={12}
                sm={3}
              >
                <label className="comman_label">Country</label>
              </Grid>
              <Grid
                className="label_responsive_dd"
                container
                item
                xs={12}
                sm={9}
              >
                <select
                  className="dropdown_city_country"
                  name="country"
                  onChange={handleChange}
                >
                  <option className="dropdown_city_country" value="">
                    Country
                  </option>

                  {allCountrylist &&
                    allCountrylist.map((country, key1) => (
                      <option
                        value={country.name}
                        cname={country.name}
                        key={key1}
                      >
                        {country.name}
                      </option>
                    ))}
                </select>
                {validationsErr && validationsErr.errors.country && (
                  <div class="errMsgConatiner">
                    {validationsErr.errors.country}
                  </div>
                )}
              </Grid>

              <Grid
                className="label_responsive_country"
                container
                item
                xs={12}
                sm={3}
              >
                <label className="comman_label">City</label>
              </Grid>

              <Grid
                className="label_responsive_dd"
                container
                item
                xs={12}
                sm={9}
              >
                <select
                  className="dropdown_city_country"
                  name="city"
                  onChange={handleChange}
                >
                  <option value="">City</option>
                  {allCitylist &&
                    allCitylist.map((city) => (
                      <option value={city} cname={city}>
                        {city}
                      </option>
                    ))}
                </select>
                {validationsErr && validationsErr.errors.city && (
                  <div class="errMsgConatiner">
                    {validationsErr.errors.city}
                  </div>
                )}
              </Grid>

              <Grid className="label_responsive" container item xs={12} sm={3}>
                <label className="comman_label">Adress</label>
              </Grid>
              <Grid className="label_responsive" container item xs={12} sm={9}>
                <textarea
                  onChange={handleChange}
                  className="input_type"
                  name="address"
                  placeholder="Adress"
                ></textarea>
                {validationsErr && validationsErr.errors.address && (
                  <div class="errMsgConatiner">
                    {validationsErr.errors.address}
                  </div>
                )}
              </Grid>

              <Grid className="label_responsive" container item xs={12} sm={3}>
                <label className="type_label">Type</label>
              </Grid>
              <Grid className="label_responsive" container item xs={12} sm={9}>
                <RadioGroup
                  style={{ float: "right" }}
                  onChange={handleChange}
                  aria-label="gender"
                  name="dealer_type"
                >
                  <div>
                    <FormControlLabel
                      value="official"
                      control={<Radio />}
                      label="Official"
                    />
                    <FormControlLabel
                      value="external"
                      control={<Radio />}
                      label="External"
                    />
                  </div>
                  {validationsErr && validationsErr.errors.dealer_type && (
                    <div
                      class="errMsgConatiner"
                      container
                      item
                      xs={12}
                      sm={12}
                      align="center"
                    >
                      {validationsErr.errors.dealer_type}
                    </div>
                  )}
                </RadioGroup>
              </Grid>

              <Grid className="label_responsive" container item xs={12} sm={3}>
                <label className="comman_label">Keycode Cost</label>
              </Grid>
              <Grid className="label_responsive" container item xs={12} sm={9}>
                <input
                  type="text"
                  onChange={handleChange}
                  name="keycode_cost"
                  className="input_type"
                  placeholder="Keycode Cost"
                ></input>
                {validationsErr && validationsErr.errors.keycode_cost && (
                  <div class="errMsgConatiner">
                    {validationsErr.errors.keycode_cost}
                  </div>
                )}
              </Grid>
            </Grid>
          </DialogContent>

          <DialogActions>
            <Button
              onClick={handleOnsubmit}
              variant="contained"
              size="medium"
              color="primary"
            >
              OK
            </Button>
            <Button
              onClick={() => {
                setOpen(false);
              }}
              variant="contained"
              size="medium"
              color="secondary"
            >
              Cancel
            </Button>
          </DialogActions>
        </Dialog>
      </Grid>

      {/* edit Dealer popup  */}

      <div>
        <Dialog
          style={{ padding: "10px" }}
          fullWidth={true}
          open={openEdit}
          onClose={() => setopenEdit(false)}
          className={classes.dialog_padding}
          maxWidth="sm"
          aria-labelledby="responsive-dialog-title"
        >
          <div className="action-dialog_top">
            <DialogTitle
              id="alert-dialog-slide-title"
              className="action_dialog_title text-center"
            >
              Edit Dealer
            </DialogTitle>
            <div
              className="action-dialog_icon"
              onClick={() => setopenEdit(false)}
            >
              <IconButton aria-label="close" className={classes.closeButton}>
                <CloseIcon />
              </IconButton>
            </div>
          </div>

          <DialogContent className="form_dealer">
            <Grid container spacing={3}>
              <Grid container item xs={12} sm={3}>
                <label className="comman_label">Name</label>
              </Grid>
              <Grid container item xs={12} sm={9}>
                <input
                  type="text"
                  className="input_type"
                  name="name"
                  value={editSingleRes.name}
                  onChange={handleChangeEdit}
                  placeholder="Name"
                ></input>
                {validationsErr && validationsErr.errors.name && (
                  <div class="errMsgConatiner">
                    {validationsErr.errors.name}
                  </div>
                )}
              </Grid>

              <Grid container item xs={12} sm={3}>
                <label className="comman_label">Country</label>
              </Grid>
              <Grid container item xs={12} sm={9}>
                <select
                  className="dropdown_city_country_edit"
                  name="country"
                  value={editSingleRes.country}
                  onChange={handleChangeEdit}
                >
                  <option className="dropdown_city_country_edit" value="">
                    Country
                  </option>
                  {allCountrylist &&
                    allCountrylist.map((country, key1) => (
                      <option
                        value={country.name}
                        cname={country.name}
                        key={key1}
                      >
                        {country.name}
                      </option>
                    ))}
                </select>
                {validationsErr && validationsErr.errors.country && (
                  <div class="errMsgConatiner">
                    {validationsErr.errors.country}
                  </div>
                )}
              </Grid>

              <Grid container item xs={12} sm={3}>
                <label className="comman_label">City</label>
              </Grid>
              <Grid container item xs={12} sm={9}>
                <select
                  className="dropdown_city_country_edit"
                  name="city"
                  value={editSingleRes.city}
                  onChange={handleChangeEdit}
                >
                  {allEditCitylist && allEditCitylist.length > 0 ? (
                    allEditCitylist.map((city) => (
                      <option value={city} cname={city}>
                        {city}
                      </option>
                    ))
                  ) : (
                    <option value={editSingleRes.city}>
                      {editSingleRes.city}
                    </option>
                  )}
                </select>
                {validationsErr && validationsErr.errors.city && (
                  <div class="errMsgConatiner">
                    {validationsErr.errors.city}
                  </div>
                )}
              </Grid>

              <Grid container item xs={12} sm={3}>
                <label className="comman_label">Adress</label>
              </Grid>
                        <Grid container item xs={12} sm={9}>
                <textarea
                  onChange={handleChangeEdit}
                  className="input_type"
                  name="address"
                  placeholder="Adress"
                  value={editSingleRes.address}
                ></textarea>
                {validationsErr && validationsErr.errors.address && (
                  <div class="errMsgConatiner">
                    {validationsErr.errors.address}
                  </div>
                )}
              </Grid>

              <Grid container item xs={12} sm={3}>
                <label className="comman_label">Keycode Cost</label>
              </Grid>
              <Grid container item xs={12} sm={9}>
                <input
                  type="text"
                  onChange={handleChangeEdit}
                  name="keycode_cost"
                  value={editSingleRes.keycode_cost}
                  className="input_type"
                  placeholder="Keycode Cost"
                ></input>
                {validationsErr && validationsErr.errors.keycode_cost && (
                  <div class="errMsgConatiner">
                    {validationsErr.errors.keycode_cost}
                  </div>
                )}
              </Grid>
            </Grid>
          </DialogContent>

          <DialogActions>
            <Button
              onClick={handleOnsubmitEdit}
              variant="contained"
              size="medium"
              color="primary"
            >
              OK
            </Button>
            <Button
              onClick={() => setopenEdit(false)}
              variant="contained"
              size="medium"
              color="secondary"
            >
              Cancel
            </Button>
          </DialogActions>
        </Dialog>
      </div>

      {alertBox ? (
        <AlertDialogSlide
          handleClosepopupalert={handleClosepopup}
          content={alertStatus}
        />
      ) : null}
      <TableContainer component={Paper}>
        <Table aria-label="collapsible table">
          <TableHead>
            <TableRow>
              <TableCell align="center">Name</TableCell>
              <TableCell align="center">Country</TableCell>
              <TableCell align="center">City</TableCell>
              <TableCell align="center">Type</TableCell>
              <TableCell align="center">Keycode Cost</TableCell>
              <TableCell align="center">Status</TableCell>
              {userrole !== "corporate" ? (
                <TableCell align="center">Actions</TableCell>
              ) : null}
              <TableCell />
            </TableRow>
          </TableHead>

          <TableBody>
            {allDealerData.length === 0 && <TableCell align="center" colSpan="12">No Records Found</TableCell>}
            {allDealerData &&
              allDealerData.map((row, key1) => <Row key={key1} row={row} />)}
          </TableBody>

        </Table>
      </TableContainer>

      <div className={classes.loaderDiv}>
        {/* <Loader /> */}
        {loaderToggle ? (
          <Backdrop
            className={classes.backdrop}
            open={true}
            onClick={handleClose}
          >
            <CircularProgress color="inherit" />
          </Backdrop>
        ) : null}
      </div>
    </div>
  );
}
