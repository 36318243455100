import React, { useEffect, useState } from "react";
import {
  Route,
  Switch,
  Redirect,
  withRouter,
} from "react-router-dom";
import classnames from "classnames";
import useStyles from "./styles";
import Header from "../Header";
import Sidebar from "../Sidebar";
import Request from "../../pages/Request/Request";
import Typography from "../../pages/typography";
import Notifications from "../../pages/notifications";
import Maps from "../../pages/maps";
import Bills from "../../pages/bills/Bills";
import Icons from "../../pages/icons";
import Charts from "../../pages/charts";
import Dealer from "../../pages/Dealer/Dealer"
import Users from "../../pages/Users/Users"
import Profile from "../../pages/Profile/Profile"
import SupportTickets from "../../pages/supportTickets/supportTickets"
import { useLayoutState, setGlobalOpenedRow, useLayoutDispatch } from "../../context/LayoutContext";
import { useUserState } from "../../context/UserContext";
import Alert from '@material-ui/lab/Alert';
import {notificationsNumber} from '../../pages/notifications/Notificationsapis';

let userDetails = JSON.parse(localStorage.getItem("userdetails"))

function Layout(props) {
  var { usersDataState, globalSuccessMsg } = useUserState();
  let userRoleData = usersDataState && usersDataState !== null ? usersDataState.role : userDetails.role
  const [visible, setVisible] = useState(false);
  var classes = useStyles();
  var layoutState = useLayoutState();
  let layoutDispatch = useLayoutDispatch();

  useEffect(() => {
    /** Get Notification count */
    const notificationsNo = notificationsNumber(localStorage.getItem("jwtauthtoken"));
    notificationsNo
      .then(response => {
        setGlobalOpenedRow(layoutDispatch,response.data.data)
      })
      .catch(error => {
    });

    setVisible(!visible)
    const timer = setTimeout(() => {
      setVisible(false)
    }, 3000);
    return () => clearTimeout(timer);
    
  }, [])

  return (
    <div className={classes.root}>
      <>
        <Header history={props.history} />
        <Sidebar />

        <div
          className={classnames(classes.content, {
            [classes.contentShift]: layoutState.isSidebarOpened,
          })}
          style={{ position: "relative" }}
        >
          <div className={classes.fakeToolbar} />
          {visible && globalSuccessMsg !== null && globalSuccessMsg !== undefined && globalSuccessMsg !== "" &&
            <div className={classes.alertMsgContainer}>
              <Alert onClose={() => { setVisible(false) }} className={classes.alertMsgBox} severity={`${globalSuccessMsg.status === "error" ? "error" : "success"}`}>{globalSuccessMsg.msg}</Alert>
            </div>
          }

          <Switch>
            <Route exact path="/app/Users">
              {(userRoleData && userRoleData !== null) && (userRoleData === "dealer" || userRoleData === "corporate") ? <Redirect to="/app/Request" /> : <Users />}
            </Route>
            <Route path="/app/Profile" component={Profile} />
            <Route path="/app/Request" component={Request} />
            <Route path="/app/Dealers" component={Dealer} />
            <Route path="/app/typography" component={Typography} />
            <Route path="/app/bills" component={Bills} />
            <Route path="/app/Users" component={Users} />
            <Route path="/app/notifications" component={Notifications} />
            <Route path="/app/support" component={SupportTickets} />
            <Route
              exact
              path="/app/ui"
              render={() => <Redirect ect to="/app/ui/icons" />}
            />
            <Route path="/app/ui/maps" component={Maps} />
            <Route path="/app/ui/icons" component={Icons} />
            <Route path="/app/ui/charts" component={Charts} />
          </Switch>
        </div>
      </>
    </div>
  );
}

export default withRouter(Layout);
