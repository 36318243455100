import { API_BASE_URL } from "../../apis/config";
import axios from "axios";

/** Get all dealers */
export const browseDealers = async authtoken => {
  return await axios.get(`${API_BASE_URL}/dealer/`, {
    headers: { "auth-token": authtoken },
  });
};

/** Suspend dealer */
export const suspendDealers = async (authtoken, id) => {
  let data = {
    address: "",
  };
  return await axios.put(`${API_BASE_URL}/dealer/suspend/${id}`, data, {
    headers: { "auth-token": authtoken },
  });
};

/** Resume dealer */
export const resumeDealers = async (authtoken, id) => {
  let data = ""
  
  return await axios.put(`${API_BASE_URL}/dealer/resume/${id}`, data, {
    headers: { "auth-token": authtoken },
  });
};

/** Suspend user from dealer lisiting */
export const suspendDealerUser = async (authtoken, id) => {
  let data = ""
  return await axios.put(`${API_BASE_URL}/user/suspend/${id}`, data, {
    headers: { "auth-token": authtoken },
  });
};

/** Resume user from dealer listing*/
export const resumeDealerUser = async (authtoken, id) => {
  let data = ""
  return await axios.put(`${API_BASE_URL}/user/resume/${id}`, data, {
    headers: { "auth-token": authtoken },
  });
};

/** Delete dealer */
export const deleteExistingDealer = async (authtoken, id) => {
  return await axios.delete(`${API_BASE_URL}/dealer/${id}`, {
    headers: { "auth-token": authtoken },
  });
};

/** Delete user */
export const deleteExistingUser = async (authtoken, id) => {
  return await axios.delete(`${API_BASE_URL}/user/${id}`, {
    headers: { "auth-token": authtoken },
  });
};

/** Add new dealer */
export const addNewDealer = async (authtoken, data) => {
  return await axios.post(`${API_BASE_URL}/dealer/`, data, {
    headers: { "auth-token": authtoken },
  });
};

/** Get single dealer */
export const getSingleDealer = async (authtoken, id) => {
  return await axios.get(`${API_BASE_URL}/dealer/${id}`, {
    headers: { "auth-token": authtoken },
  });
};

/** Edit dealer */
export const editSingleDealer = async (authtoken, id, data) => {
  return await axios.put(`${API_BASE_URL}/dealer/${id}`, data, {
    headers: { "auth-token": authtoken },
  });
};

/** Get all country */
export const getAllCountry = async () => {
  return await axios.get(`${API_BASE_URL}/general/countries`);
};

/** Get all city from country */
export const getAllCityFromCountry = async id => {
  return await axios.get(`${API_BASE_URL}/general/cities/${id}`);
};
