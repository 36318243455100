import React, { useState, useEffect } from "react";
import { Drawer, IconButton, List } from "@material-ui/core";
import {
  ScreenShare as ScreenShareIcon,
  NotificationsNone as NotificationsIcon,
  Person as AccountIcon,
  ArrowBack as ArrowBackIcon,
  SettingsPower as SettingsPowerIcon,
  DirectionsCar as DirectionsCarIcon,
  PeopleOutline as PeopleOutlineIcon,
} from "@material-ui/icons";
import ReceiptSharpIcon from '@material-ui/icons/ReceiptSharp';
import SettingsApplicationsIcon from '@material-ui/icons/SettingsApplications';
import { useTheme } from "@material-ui/styles";
import { withRouter } from "react-router-dom";
import classNames from "classnames";
import { Badge } from "../Wrappers";
import { API_BASE_URL } from "../../apis/config";
import axios from "axios";
// styles
import useStyles from "./styles";
// components
import SidebarLink from "./components/SidebarLink/SidebarLink";
// context
import {
  useLayoutState,
  useLayoutDispatch,
  toggleSidebar,
} from "../../context/LayoutContext";



function Sidebar({ location }) {
  var classes = useStyles();
  var theme = useTheme();

  // global
  var { isSidebarOpened,openedRowState } = useLayoutState();
  var layoutDispatch = useLayoutDispatch();
  let userdetails = JSON.parse(localStorage.getItem("userdetails"));
  let userrole = userdetails.role;
  // local
  var [isPermanent, setPermanent] = useState(true);
  const [allNotifications] = useState([]);
  
const admin = [{
  id: 0,
  label: "Users",
  link: "/app/Users",
  icon: <PeopleOutlineIcon />,
},
 {
    id: 1,
    label: "Dealers",
    link: "/app/Dealers",
    icon: <DirectionsCarIcon  />
  },
  { 
    id: 0, 
    label: "Requests", 
    link: "/app/Request", 
    icon: <ScreenShareIcon />
   },
  { 
    id: 2, 
    label: "Support Tickets", 
    link: "/app/support", 
    icon: <AccountIcon /> 
  },
  {
    id: 3,
    label: "Notifications",
    link: "/app/notifications",
    icon: <Badge
    badgeContent={openedRowState !== "" && openedRowState !== 0 ? openedRowState : "0"}
    max={9999}
    color="warning"
  >
    <NotificationsIcon  />
  </Badge>,
  },
  { 
    id: 4, 
    label: "Bills",
    link: "/app/bills",
    icon: <ReceiptSharpIcon />
  },
  { 
    id: 5, 
    label: "Profile", 
    link: "/app/Profile", 
    icon: <SettingsApplicationsIcon /> 
  },
  {
    id: 6, 
    label: "Logout",
    link: "/app/logout", 
    icon: <SettingsPowerIcon  /> 
  },

];

const corporate = [
{
id: 0,
label: "Dealers",
link: "/app/Dealers",
icon: <DirectionsCarIcon  />
},
{ 
id: 0, 
label: "Requests", 
link: "/app/Request", 
icon: <ScreenShareIcon />
 },
{ 
id: 1, 
label: "Support Tickets", 
link: "/app/support", 
icon: <AccountIcon /> 
},
{
id: 2,
label: "Notifications",
link: "/app/notifications",
icon: <Badge
badgeContent={openedRowState !== "" && openedRowState !== 0 ? openedRowState : "0"}
color="warning"
>

<NotificationsIcon />

</Badge>,
},

{ 
id: 3, 
label: "Bills",
link: "/app/bills",
icon: <ReceiptSharpIcon />
},

{ 
id: 4, 
label: "Profile", 
link: "/app/Profile", 
icon: <SettingsApplicationsIcon /> 
},
{
 id: 5, 
 label: "Logout",
  link: "/app/logout", 
  icon: <SettingsPowerIcon  /> 
},
];

const dealer = [
{ 
id: 0, 
label: "Requests", 
link: "/app/Request", 
icon: <ScreenShareIcon />
},
{
id: 1,
label: "Notifications",
link: "/app/notifications",
icon: <Badge
badgeContent={openedRowState !== "" && openedRowState !== 0 ? openedRowState : "0"}
color="warning"
>
<NotificationsIcon  />
</Badge>,
},
{ 
id: 2, 
label: "Bills",
link: "/app/bills",
icon: <ReceiptSharpIcon />
},
{ 
id: 3, 
label: "Profile", 
link: "/app/Profile", 
icon: <SettingsApplicationsIcon /> 
},
{
 id: 4, 
 label: "Logout",
  link: "/app/logout", 
  icon: <SettingsPowerIcon  /> 
},
];

const any = [

{ 
  id: 0, 
  label: "Profile", 
  link: "/app/Profile", 
  icon: <SettingsApplicationsIcon /> 
},

{
   id: 1, 
   label: "Logout",
    link: "/app/logout", 
    icon: <SettingsPowerIcon  /> 
  },
];
useEffect(() => {
  
  // const notificationsNo = notificationsNumber(localStorage.getItem("jwtauthtoken"));
  // notificationsNo
  //   .then(response => {
  //     setAllNotifications(response.data.data);
  //  })
  //   .catch(error => {
  // });
  },[allNotifications])


  useEffect(function() {
    window.addEventListener("resize", handleWindowWidthChange);
    handleWindowWidthChange();
    return function cleanup() {
      window.removeEventListener("resize", handleWindowWidthChange);
    };
  });

  return (
    <Drawer
      variant={isPermanent ? "permanent" : "temporary"}
      className={classNames(classes.drawer, {
        [classes.drawerOpen]: isSidebarOpened,
        [classes.drawerClose]: !isSidebarOpened,
      })}
      classes={{
        paper: classNames({
          [classes.drawerOpen]: isSidebarOpened,
          [classes.drawerClose]: !isSidebarOpened,
        }),
      }}
      open={isSidebarOpened}
    >
      <div className={classes.toolbar} />
      <div className={classes.mobileBackButton}>
        <IconButton onClick={() => toggleSidebar(layoutDispatch)}>
          <ArrowBackIcon
            classes={{
              root: classNames(classes.headerIcon, classes.headerIconCollapse),
            }}
          />
        </IconButton>
      </div>
      {<List className={classes.sidebarList}>
        {userrole === "admin" && admin.map((link1,key1) => (
          <SidebarLink
            key={key1}
            location={location}
            isSidebarOpened={isSidebarOpened}
            {...link1}
          />
        ))}
        {userrole === "corporate" && corporate.map((link2,key2) => (
          <SidebarLink
            key={key2}
            location={location}
            isSidebarOpened={isSidebarOpened}
            {...link2}
          />
        ))}
        {userrole === "dealer" && dealer.map((link3,key3) => (
          <SidebarLink
            key={key3}
            location={location}
            isSidebarOpened={isSidebarOpened}
            {...link3}
          />
        ))}
        {userrole === "any" && any.map((link4,key4) => (
          <SidebarLink
            key={key4}
            location={location}
            isSidebarOpened={isSidebarOpened}
            {...link4}
          />
        ))}
      </List>}
      <span className={classes.poweredBy}>Powered By Smart Otomation Solutions</span>

    </Drawer>
  );

  // ######################### //
  function handleWindowWidthChange() {
    var windowWidth = window.innerWidth;
    var breakpointWidth = theme.breakpoints.values.md;
    var isSmallScreen = windowWidth < breakpointWidth;

    if (isSmallScreen && isPermanent) {
      setPermanent(false);
    } else if (!isSmallScreen && !isPermanent) {
      setPermanent(true);
    }
  }
}

export const notificationsNumber = async (authtoken) => {
  return await axios.get(`${API_BASE_URL}/notification`,  {
    headers: { "auth-token": authtoken },
  });
};

export default withRouter(Sidebar);
