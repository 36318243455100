import React, { useEffect, useState, useRef } from "react";
import {
  Grid,
  CircularProgress,
  Typography,
  Button,
  
  TextField,
  Fade,
} from "@material-ui/core";
import { withRouter } from "react-router-dom";
import {FORGOT_PASS_API} from '../../apis/config'
import axios from 'axios';
// styles
import useStyles from "./styles";
import ReCAPTCHA from "react-google-recaptcha";
// logo
// import logo from "./logo.svg";
// import google from "../../images/google.svg";
import corporateLogo from "../../images/logo_placeholder-square.png";
// context
import { useUserDispatch, fogotpassword } from "../../context/UserContext";

const TEST_SITE_KEY = "6LeNOhoaAAAAACOn2KH2Ey6orwqI7FTOrREnP88I";
const DELAY = 1000;

function ForgotPassword(props) {
  var classes = useStyles();
  var userDispatch = useUserDispatch();
  const [isLoading, setIsLoading] = useState(false);
  const [errorEmailForgot, setErrorEmailForgot] = useState("");
  const [loginValue, setLoginValue] = useState("");
  const [captchaValue, setCaptchaValue] = useState("");
  const [load, setLoad] = useState(false);

  const [captchaError, setCaptchaError] = useState("")
 const _reCaptchaRef = useRef();

  useEffect(() => {
    setTimeout(() => {
      setLoad(true);
    }, DELAY);
  }, []);


  const handleChangeCaptcha = value => {
    setCaptchaValue(value);
    setCaptchaError("")
   
  };

  const HandleChangeEmail = (event) => {
    setLoginValue(event.target.value)
    setErrorEmailForgot(null)
  }

  function forgotPass(login, captchaValue) {
  
    setIsLoading(true);
  
    if (login === "" || login === undefined) {
      setErrorEmailForgot("Please Enter Email");
      setIsLoading(false);
    } else if (!/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(login)) {
      setErrorEmailForgot("Please Enter Valid Email");
      setIsLoading(false);
       
    }else if (captchaValue === "") {
      setCaptchaError("Please Enter Captcha");
      setIsLoading(false);
    } else {
      let AllData = {
        email : login,
      }
      axios.post(
        `${FORGOT_PASS_API}`, AllData)
       
        .then((res) => {
          setIsLoading(false);
          fogotpassword(res.data.message, props.history, userDispatch)
        
        })
        .catch((error) => {
         
          setIsLoading(false);
          setErrorEmailForgot(error.message);
        });
      
    }
  }
  


  return (
    <Grid container className={classes.container}>
     
      <div className={classes.formContainer}>
        <div className={classes.form}>
         
         
            <React.Fragment>
             
              <Button size="large" className={classes.googleButton}>
                <img src={corporateLogo} alt="Login" className={classes.googleIcon} />
               
              </Button> 
              <div className={classes.inputEmail}>
              <TextField
                id="email"
                InputProps={{
                  classes: {
                    underline: classes.textFieldUnderline,
                    input: classes.textField,
                  },
                }}
                value={loginValue}
                onChange={e => HandleChangeEmail(e)}
                margin="normal"
                placeholder="Enter Your Email Adress"
                type="email"
                fullWidth={true}
              />
              </div>
              {errorEmailForgot && errorEmailForgot ?<Fade in={errorEmailForgot}>
                <Typography color="secondary" className={classes.errorMessage}>
                {errorEmailForgot}
                </Typography>
              </Fade> : null}
              
              
              <div className={classes.formButtons}>
               
               
              </div>
            </React.Fragment>
         
          
        <span className={classes.captchaTitle}>Please check the box below to proceed.</span>
        <div className={classes.captchaDiv}>
       
        {load && (
          <ReCAPTCHA
            style={{ display: "inline-block" }}
            theme="light"
            ref={_reCaptchaRef}
            sitekey={TEST_SITE_KEY}
            onChange={handleChangeCaptcha}
          />
        )}
      </div>
      {captchaError && captchaError ?<Fade in={captchaError}>
                <Typography color="secondary" className={classes.errorMessage}>
                {captchaError}
                </Typography>
              </Fade> : null}
      <div className={classes.loginBtnDiv}>
          <Button className={classes.loginBtn}
                   
                    onClick={() =>
                        forgotPass(
                        loginValue,
                        captchaValue,                        
                      )
                    }
                    variant="contained"
                    color="primary"
                    size="large"
                  >
                    Submit  {isLoading ? (
                  <CircularProgress size={26} className={classes.loginLoader} />
                ) : null}
                  </Button>
                  </div>
        </div>
        
        
      </div>
      
    </Grid>
  );
}

export default withRouter(ForgotPassword);
