import React, { useEffect, useState } from "react";
import { Chip } from "@material-ui/core";
import "../Users/User.css";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import Paper from "@material-ui/core/Paper";
import { withStyles } from "@material-ui/core/styles";
import { Create as CreateIcon } from "@material-ui/icons";
import IconButton from "@material-ui/core/IconButton";
import TableFooter from "@material-ui/core/TableFooter";
import Dialog from "@material-ui/core/Dialog";
import DialogContent from "@material-ui/core/DialogContent";
import Grid from "@material-ui/core/Grid";
import {
  addNewUser,
  editSingleUser,
  browseUsers,
  suspendUsers,
  resumeUsers,
  deleteExistingUser,
  getSingleUser,
  reinviteSingleUser,
  getAllDealers,
} from "./Usersapis";
import AlertDialogSlide from "../alertpopup/AlertDialogSlide";
import Backdrop from "@material-ui/core/Backdrop";
import CircularProgress from "@material-ui/core/CircularProgress";
import useStyles from "./styles";
import CloseIcon from "@material-ui/icons/Close";
import DialogTitle from "@material-ui/core/DialogTitle";
import DialogActions from "@material-ui/core/DialogActions";
import { Button } from "../../components/Wrappers/Wrappers";
import classnames from "classnames";
import Pagination from "@material-ui/lab/Pagination";
import {
  useUserDispatch,
  useUserState,
  setGlobalSuccessMsg,
} from "../../context/UserContext";

const StyledTableCell = withStyles((theme) => ({
  head: {
    backgroundColor: theme.palette.common.white,
  },
  body: {
    fontSize: 14,
  },
}))(TableCell);

const StyledTableRow = withStyles((theme) => ({
  root: {
    "&:nth-of-type(odd)": {
      backgroundColor: theme.palette.common.white,
    },
  },
}))(TableRow);

export default function User(props) {
  var { setopen } = useUserState();
  var userDispatch = useUserDispatch();
  const classes = useStyles();
  const [page, setPage] = React.useState(1);
  const [open, setOpen] = React.useState(false);
  const [noOfPages, setNoOfPages] = useState();
  const [loaderToggle, setLoaderToggle] = useState(true);
  const [editSingleRes, setEditSingleRes] = useState([]);
  const [editSingleResData, setEditSingleResData] = useState({});
  const [allDealerData, setAllDealerData] = useState([]);
  const [alertBox, setAlertBox] = useState(setopen.setopen);
  const [suspendid, setssuspendid] = useState("");
  const [suspendstatus, setSuspendstatus] = useState("");
  const [alertStatus, setalertStatus] = useState("");
  const [loadComponent, setLoadComponent] = useState([]);
  const [allFomrData, setallFomrData] = useState({
    name: "",
    email: "",
    phone: "",
    employeeId: "",
    role: "",
  });
  const [openEdit, setopenEdit] = useState(false);
  const [validationsErr, setValidationsErr] = useState("");
  const [editValidationsErr, setEditValidationsErr] = useState("");
  const [dealersList, setDealersList] = useState([]);
  const authtoken = localStorage.getItem("jwtauthtoken");
  let userdetails = JSON.parse(localStorage.getItem("userdetails"));
  let userrole = userdetails.role;
  let statusSlug = {
    active: "Active",
    deActivated: "Deactivated",
    pending: "Pending",
    suspended: "Suspended",
  };
  const itemsPerPage = 10;

  useEffect(() => {
    setLoaderToggle(true);
    /** Browse User Data API*/
    const browseUserData = browseUsers(
      localStorage.getItem("jwtauthtoken"),
      page,
    );
    browseUserData
      .then((response) => {
        setLoaderToggle(false);
        setAllDealerData(response.data.data.users);
        setNoOfPages(Math.ceil(response.data.pagination.total / itemsPerPage));
      })
      .catch((error) => {
        setLoaderToggle(false);
      });
    /** Get Dealer Data API */
    const getDealersListData = () => {
      setLoaderToggle(true);
      const getAllDealersResp = getAllDealers(
        localStorage.getItem("jwtauthtoken"),
      );
      getAllDealersResp
        .then((response) => {
          setDealersList(response.data.data.dealers);
        })
        .catch((error) => {});
    };

    getDealersListData();
    const timer = setTimeout(() => {}, 10000);
    return () => clearTimeout(timer);
  }, [loadComponent, page]);

  /** HandleChange of Form  */
  const handleChange = (event) => {
    setallFomrData({
      ...allFomrData,
      [event.target.name]:
        event.target.value !== "" ? event.target.value : null,
    });
  };

  const handleClickOpen = () => {
    setOpen(true);
    setValidationsErr({ errors: [] });
  };

  const handleClose = () => {
    setOpen(false);
    setValidationsErr({ errors: "" });
  };
  /** Delete a User*/
  const HandleDelete = (id) => {
    setAlertBox(true);
    setssuspendid(id);
    setSuspendstatus("Delete");
    setalertStatus("Delete a User");
  };
  /** Resume And Suspend User*/
  const HandleSuspend = (id, status) => {
    setAlertBox(true);
    setssuspendid(id);
    setSuspendstatus(status);
    setalertStatus(status === "suspended" ? "Resume a User" : "Suspend a User");
  };
  /** Reinvite a User*/
  const HandleReinvite = (id, status) => {
    setAlertBox(true);
    setssuspendid(id);
    setSuspendstatus(status);
    setalertStatus("Reinvite a User");
  };
  /** Success & Error Message */
  const handleClosepopup = (value) => {
    if (
      suspendstatus !== "suspended" &&
      suspendstatus !== "pending" &&
      value === "ok" &&
      suspendstatus !== "Delete"
    ) {
      const suspendUser = suspendUsers(authtoken, suspendid);
      suspendUser
        .then((response) => {
          setGlobalSuccessMsg(response.data.message, userDispatch, "success");
          setLoadComponent(response);
        })
        .catch((error) => {
          error.response &&
            setGlobalSuccessMsg(
              error.response.data.message,
              userDispatch,
              "error",
            );
          setGlobalSuccessMsg("", userDispatch);
        });
    } else {
      if (
        value === "ok" &&
        suspendstatus !== "Delete" &&
        suspendstatus !== "pending"
      ) {
        const resumeUser = resumeUsers(authtoken, suspendid);
        resumeUser
          .then((response) => {
            setGlobalSuccessMsg(response.data.message, userDispatch, "success");
            setLoadComponent(response);
          })
          .catch((error) => {
            error.response &&
              setGlobalSuccessMsg(
                error.response.data.message,
                userDispatch,
                "error",
              );
          });
      }
    }
    if (value === "ok" && suspendstatus === "Delete") {
      const deleteRes = deleteExistingUser(authtoken, suspendid);
      deleteRes
        .then((response) => {
          setGlobalSuccessMsg(response.data.message, userDispatch, "success");
          setLoadComponent(response);
        })
        .catch((error) => {
          error.response &&
            setGlobalSuccessMsg(
              error.response.data.message,
              userDispatch,
              "error",
            );
        });
    }
    // Reinvite user API
    if (value === "ok" && suspendstatus === "pending") {
      const reinviteUserRes = reinviteSingleUser(authtoken, suspendid);
      reinviteUserRes
        .then((response) => {
          setLoadComponent(response);
          setGlobalSuccessMsg(
            "User has been successfully reinvited",
            userDispatch,
            "success",
          );
        })
        .catch((error) => {
          error.response &&
            setGlobalSuccessMsg(
              error.response.data.message,
              userDispatch,
              "error",
            );
        });
    }
    setAlertBox(false);
  };
  /**  Edit a User*/
  const HandleEdit = (id) => {
    setEditValidationsErr({ errors: [] });
    setLoaderToggle(true);
    const singleUser = getSingleUser(authtoken, id);
    singleUser
      .then((response) => {
        setEditSingleRes(response.data.data.user);
        setLoaderToggle(false);
        setopenEdit(true);
      })
      .catch((error) => {});
  };
  /** Validation of Form*/
  const handleValidation = () => {
    let fields = allFomrData;
    let errors = {};
    let formIsValid = true;

    if (!fields["name"]) {
      formIsValid = false;
      errors["name"] = "Name can not be empty";
    }
    if (fields["phone"] && fields["phone"].length !== 14) {
      formIsValid = false;
      errors["phone"] = "Phone number must be 14 digits";
    }
    if (!fields["phone"]) {
      formIsValid = false;
      errors["phone"] = "Phone number can not be empty";
    }
    if (!/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(fields["email"])) {
      formIsValid = false;
      errors["email"] = "Email is not valid";
    }
    if (!fields["email"]) {
      formIsValid = false;
      errors["email"] = "Email can not be empty";
    }
    if (fields["employeeId"] === "") {
      formIsValid = false;
      errors["employeeId"] = "Employee Id can not be empty";
    }
    if (!fields["role"]) {
      formIsValid = false;
      errors["role"] = "Please select the Role";
    }
    if (fields["role"] === "dealer" && !fields["dealerId"]) {
      formIsValid = false;
      errors["dealerId"] = "Please select the Dealer";
    }
    setValidationsErr({ errors: errors });
    return formIsValid;
  };
  /** Validation of Update Form*/
  const handleEditValidation = () => {
    let fields = editSingleResData;
    let errors = {};
    let formIsValid = true;

    if (fields.name === "") {
      formIsValid = false;
      errors["name"] = "Name can not be empty";
    }
    if (fields["phone"] && fields["phone"].length !== 14) {
      formIsValid = false;
      errors["phone"] = "Phone number must be 14 digits";
    }
    if (fields["phone"] === "") {
      formIsValid = false;
      errors["phone"] = "Phone number can not be empty";
    }
    if (
      fields["email"] &&
      !/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(fields["email"])
    ) {
      formIsValid = false;
      errors["email"] = "Email is not valid";
    }
    if (fields["email"] === "") {
      formIsValid = false;
      errors["email"] = "Email can not be empty";
    }
    setEditValidationsErr({ errors: errors });
    return formIsValid;
  };
  /** Add New User API*/
  const handleOnsubmit = () => {
    if (handleValidation()) {
      const addDealer = addNewUser(authtoken, allFomrData);
      addDealer
        .then((response) => {
          setLoadComponent(response);
          setGlobalSuccessMsg(
            "User has been successfully Added",
            userDispatch,
            "success",
          );
          setOpen(false);
        })
        .catch((error) => {
          let errors = {};
          error.response.data.errors &&
            error.response.data.errors.map((item) => {
              return (errors[item.param] = item.msg);
            });
          errors && Object.entries(errors).length > 0
            ? setValidationsErr({ errors: errors })
            : error.response &&
              setGlobalSuccessMsg(
                error.response.data.message,
                userDispatch,
                "error",
              );
        });
    } else {
      setOpen(true);
    }
  };
  /** Update New User API*/
  const handleOnsubmitEdit = () => {
    if (handleEditValidation()) {
      const editDealer = editSingleUser(
        authtoken,
        editSingleRes._id,
        editSingleResData,
      );
      editDealer
        .then((response) => {
          if (response.data.success === true)
            setGlobalSuccessMsg(
              "User has been successfully Updated",
              userDispatch,
              "success",
            );
          setLoadComponent(response);
          setopenEdit(false);
        })
        .catch((error) => {
          let errors = {};
          error.response.data.errors &&
            error.response.data.errors.map((item) => {
              return (errors[item.param] = item.msg);
            });
          errors && Object.entries(errors).length > 0
            ? setEditValidationsErr({ errors: errors })
            : error.response &&
              setGlobalSuccessMsg(
                error.response.data.message,
                userDispatch,
                "error",
              );
        });
    } else {
      setopenEdit(true);
    }
  };
  /** HandleChange Of Edit Form*/
  const handleChangeEdit = (event) => {
    setEditSingleResData({
      ...editSingleResData,
      [event.target.name]: event.target.value,
    });
  };

  const handleUserArr = (value) => {
    let userArr = [];
    noOfPages !== undefined &&
      allDealerData
        .slice((value - 1) * itemsPerPage, value * itemsPerPage)
        .map((item, index) => {
          return userArr.push(item._id);
        });
  };
  /** Pagination*/
  const handlePaginationChange = (event, value) => {
    setPage(value);
    handleUserArr(value);
  };

  return (
    <>
      <div className="user_button">
        <Grid
          container
          direction="row"
          justify="flex-end"
          alignItems="center"
          className="filterContainer"
        >
          <Button
            variant="contained"
            size="medium"
            color="success"
            onClick={handleClickOpen}
          >
            New User
          </Button>
        </Grid>
      </div>
      <>
        <Dialog
          style={{ padding: "10px" }}
          fullWidth={true}
          open={open}
          onClose={() => setOpen(false)}
          className={classes.dialog_padding}
          maxWidth="sm"
          aria-labelledby="responsive-dialog-title"
        >
          <div class="action-dialog_top">
            <DialogTitle
              id="alert-dialog-slide-title"
              class="action_dialog_title text-center"
            >
              Add User
            </DialogTitle>
            <div class="action-dialog_icon" onClick={() => setOpen(false)}>
              <IconButton aria-label="close" className={classes.closeButton}>
                <CloseIcon />
              </IconButton>
            </div>
          </div>
          <DialogContent className="user_form form_dealer">
            <Grid container spacing={3}>
              <Grid className="user_label" container item xs={12} sm={3}>
                <label className="comman_label">Name</label>
              </Grid>
              <Grid className="user_label" container item xs={12} sm={9}>
                <input
                  type="text"
                  className="input_type"
                  placeholder="Name"
                  name="name"
                  onChange={handleChange}
                ></input>
                {validationsErr && validationsErr.errors.name && (
                  <div class="errMsgConatiner">
                    {validationsErr.errors.name}
                  </div>
                )}
              </Grid>

              <Grid className="user_label" container item xs={12} sm={3}>
                <label className="comman_label">Mobile</label>
              </Grid>
              <Grid className="user_label" container item xs={12} sm={9}>
                <input
                  type="text"
                  className="input_type"
                  placeholder="Mobile"
                  name="phone"
                  onChange={handleChange}
                ></input>
                {validationsErr && validationsErr.errors.phone && (
                  <div class="errMsgConatiner">
                    {validationsErr.errors.phone}
                  </div>
                )}
              </Grid>

              <Grid className="user_label" container item xs={12} sm={3}>
                <label className="comman_label">Email</label>
              </Grid>
              <Grid className="user_label" container item xs={12} sm={9}>
                <input
                  type="email"
                  className="input_type"
                  placeholder="Email"
                  name="email"
                  onChange={handleChange}
                ></input>
                {validationsErr && validationsErr.errors.email && (
                  <div class="errMsgConatiner">
                    {validationsErr.errors.email}
                  </div>
                )}
              </Grid>

              <Grid className="user_label" container item xs={12} sm={3}>
                <label className="comman_label">Employee ID</label>
              </Grid>
              <Grid className="user_label" container item xs={12} sm={9}>
                <input
                  type="text"
                  className="input_type"
                  placeholder="Employee ID"
                  name="employeeId"
                  onChange={handleChange}
                ></input>
                {validationsErr && validationsErr.errors.employeeId && (
                  <div class="errMsgConatiner">
                    {validationsErr.errors.employeeId}
                  </div>
                )}
              </Grid>

              <Grid
                className="user_label_country"
                container
                item
                xs={12}
                sm={3}
              >
                <label className="comman_label">Role</label>
              </Grid>
              <Grid className="user_label_dd" container item xs={12} sm={9}>
                <select
                  className="dropdown_city_country"
                  name="role"
                  onChange={handleChange}
                >
                  <option value="" selected>
                    Role
                  </option>
                  <option value="dealer">Dealer</option>
                  <option value="corporate">Corporate</option>
                  <option value="admin">Admin</option>
                </select>
                {validationsErr && validationsErr.errors.role && (
                  <div class="errMsgConatiner">
                    {validationsErr.errors.role}
                  </div>
                )}
              </Grid>

              {allFomrData["role"] && allFomrData["role"] === "dealer" && (
                <>
                  <Grid
                    className="user_label_country"
                    container
                    item
                    xs={12}
                    sm={3}
                  >
                    <label className="comman_label">Dealer</label>
                  </Grid>
                  <Grid className="user_label_dd" container item xs={12} sm={9}>
                    <select
                      className="dropdown_city_country"
                      name="dealerId"
                      onChange={handleChange}
                    >
                      <option value="" selected>
                        Dealer List
                      </option>
                      {dealersList &&
                        dealersList.length > 0 &&
                        dealersList.map((item, index) => (
                          <>
                            <option key={index} value={item._id}>
                              {item.name}
                            </option>
                          </>
                        ))}
                    </select>
                    {validationsErr && validationsErr.errors.dealerId && (
                      <div class="errMsgConatiner">
                        {validationsErr.errors.dealerId}
                      </div>
                    )}
                  </Grid>
                </>
              )}
            </Grid>
          </DialogContent>

          <DialogActions>
            <Button
              onClick={handleOnsubmit}
              variant="contained"
              size="medium"
              color="primary"
            >
              OK
            </Button>
            <Button
              onClick={() => {
                setOpen(false);
              }}
              variant="contained"
              size="medium"
              color="secondary"
            >
              Cancel
            </Button>
          </DialogActions>
        </Dialog>
      </>

      {alertBox ? (
        <AlertDialogSlide
          handleClosepopupalert={handleClosepopup}
          content={alertStatus}
        />
      ) : null}
      <TableContainer component={Paper}>
        <Table className={classes.table} aria-label="customized table">
          <TableHead>
            <TableRow>
              <StyledTableCell align="center">Name</StyledTableCell>
              <StyledTableCell align="center">Email</StyledTableCell>
              <StyledTableCell align="center">Mobile</StyledTableCell>
              <StyledTableCell align="center">Dealer</StyledTableCell>
              <StyledTableCell align="center">Status</StyledTableCell>
              <StyledTableCell align="center">Actions</StyledTableCell>
              <StyledTableCell />
            </TableRow>
          </TableHead>
          <TableBody>
          {allDealerData && allDealerData.length === 0 && 
            <StyledTableRow>
                <StyledTableCell colSpan="12" align="center">
                   No Records Found
                </StyledTableCell>
              </StyledTableRow>}
            {noOfPages !== undefined &&
              allDealerData
                .slice(0, itemsPerPage)
                .map((allDealerData, index) => (
                  <StyledTableRow key={index}>
                    <StyledTableCell align="center">
                      {allDealerData.name}
                    </StyledTableCell>
                    <StyledTableCell align="center">
                      {allDealerData.email}
                    </StyledTableCell>
                    <StyledTableCell align="center">
                      {allDealerData.phone}
                    </StyledTableCell>
                    <StyledTableCell align="center">
                      {allDealerData.dealerId && allDealerData.dealerId.name}
                    </StyledTableCell>

                    <StyledTableCell align="center">
                      <Chip
                        className={classes.chipPosittion}
                        label={statusSlug[allDealerData.status]}
                        classes={{
                          root:
                            classes[
                              allDealerData.status === "deActivated"
                                ? "secondary"
                                : allDealerData.status === "pending"
                                ? "warning"
                                : allDealerData.status === "active"
                                ? "success"
                                : allDealerData.status === "suspended"
                                ? "info"
                                : "secondary"
                            ],
                        }}
                      />
                    </StyledTableCell>

                    {userrole !== "" ? (
                      <StyledTableCell align="center">
                        <div className={classes.buttonsContainer}>
                          {allDealerData.status !== "deActivated" && (
                            <>
                              {allDealerData.status === "suspended" && (
                                <Button
                                  variant="contained"
                                  color="primary"
                                  style={{ marginRight: "10px" }}
                                  onClick={() =>
                                    HandleSuspend(
                                      allDealerData._id,
                                      allDealerData.status,
                                    )
                                  }
                                  className={classnames(
                                    classes.notificationCallButton,
                                  )}
                                >
                                  Resume
                                </Button>
                              )}

                              {allDealerData.status !== "deActivated" && (
                                <Button
                                  variant="contained"
                                  color="secondary"
                                  onClick={() =>
                                    HandleDelete(allDealerData._id)
                                  }
                                  className={classnames(
                                    classes.notificationCallButton,
                                  )}
                                >
                                  Delete
                                </Button>
                              )}

                              {allDealerData.status === "pending" &&
                                userrole === "admin" && (
                                  <Button
                                    variant="contained"
                                    color="success"
                                    style={{ marginLeft: "10px" }}
                                    onClick={() =>
                                      HandleReinvite(
                                        allDealerData._id,
                                        allDealerData.status,
                                      )
                                    }
                                    className={classnames(
                                      classes.notificationCallButton,
                                    )}
                                  >
                                    Reinvite
                                  </Button>
                                )}

                              {allDealerData.status === "active" && (
                                <Button
                                  variant="contained"
                                  color="info"
                                  style={{ marginLeft: "10px" }}
                                  onClick={() =>
                                    HandleSuspend(
                                      allDealerData._id,
                                      allDealerData.status,
                                    )
                                  }
                                  className={classnames(
                                    classes.notificationCallButton,
                                  )}
                                >
                                  Suspend
                                </Button>
                              )}
                            </>
                          )}
                        </div>
                      </StyledTableCell>
                    ) : null}

                    <StyledTableCell
                      className="craeteicon_user"
                      onClick={() => HandleEdit(allDealerData._id)}
                    >
                      {allDealerData.status !== "deActivated" && <CreateIcon />}
                    </StyledTableCell>
                  </StyledTableRow>
                ))}
          </TableBody>
        </Table>
        <Table className={classes.table} aria-label="customized table">
          
        {allDealerData.length > 0 && 
          <TableFooter style={{ float: "right" }}>
            {!loaderToggle && (
              <Grid item xs={12} className={classes.notificationPagination}>
                <TableRow>
                  {noOfPages !== null && noOfPages !== undefined && (
                    <Pagination
                      count={noOfPages}
                      page={page}
                      onChange={handlePaginationChange}
                      defaultPage={1}
                      color="primary"
                      size="large"
                    />
                  )}
                </TableRow>
              </Grid>
            )}
          </TableFooter>
}
        </Table>
      </TableContainer>

      {/* --------------------edit user---------------------- */}

      <div>
        <Dialog
          style={{ padding: "10px" }}
          fullWidth={true}
          open={openEdit}
          onClose={() => setopenEdit(false)}
          className={classes.dialog_padding}
          maxWidth="sm"
          aria-labelledby="responsive-dialog-title"
        >
          <div class="action-dialog_top">
            <DialogTitle
              id="alert-dialog-slide-title"
              class="action_dialog_title text-center"
            >
              Edit User
            </DialogTitle>
            <div class="action-dialog_icon" onClick={() => setopenEdit(false)}>
              <IconButton aria-label="close" className={classes.closeButton}>
                <CloseIcon />
              </IconButton>
            </div>
          </div>

          <DialogContent className="user_form form_dealer">
            <Grid container spacing={3}>
              <Grid className="comman_label" container item xs={12} sm={3}>
                <label className="comman_label">Name</label>
              </Grid>
              <Grid container className="comman_label" item xs={12} sm={9}>
                <input
                  type="text"
                  className="input_type_user"
                  placeholder="Name"
                  defaultValue={editSingleRes.name}
                  name="name"
                  onChange={handleChangeEdit}
                ></input>
                {editValidationsErr && editValidationsErr.errors.name && (
                  <div class="errMsgConatiner">
                    {editValidationsErr.errors.name}
                  </div>
                )}
              </Grid>

              <Grid className="comman_label" container item xs={12} sm={3}>
                <label className="comman_label">Mobile</label>
              </Grid>
              <Grid className="comman_label" container item xs={12} sm={9}>
                <input
                  type="text"
                  className="input_type_user"
                  placeholder="Mobile"
                  defaultValue={editSingleRes.phone}
                  name="phone"
                  onChange={handleChangeEdit}
                ></input>
                {editValidationsErr && editValidationsErr.errors.phone && (
                  <div class="errMsgConatiner">
                    {editValidationsErr.errors.phone}
                  </div>
                )}
              </Grid>

              <Grid className="comman_label" container item xs={12} sm={3}>
                <label className="comman_label">Email</label>
              </Grid>
              <Grid className="comman_label" container item xs={12} sm={9}>
                <input
                  type="email"
                  className="input_type_user"
                  placeholder="Email"
                  defaultValue={editSingleRes.email}
                  name="email"
                  onChange={handleChangeEdit}
                  disabled
                ></input>
                {editValidationsErr && editValidationsErr.errors.email && (
                  <div class="errMsgConatiner">
                    {editValidationsErr.errors.email}
                  </div>
                )}
              </Grid>
            </Grid>
          </DialogContent>

          <DialogActions>
            <Button
              onClick={handleOnsubmitEdit}
              variant="contained"
              size="medium"
              color="primary"
            >
              OK
            </Button>
            <Button
              onClick={() => {
                setopenEdit(false);
              }}
              variant="contained"
              size="medium"
              color="secondary"
            >
              Cancel
            </Button>
          </DialogActions>
        </Dialog>
      </div>

      <div className={classes.loaderDiv}>
        {/* <Loader /> */}
        {loaderToggle ? (
          <Backdrop
            className={classes.backdrop}
            open={true}
            onClick={handleClose}
          >
            <CircularProgress color="inherit" />
          </Backdrop>
        ) : null}
      </div>
    </>
  );
}
