import { API_BASE_URL } from '../../apis/config';
import axios from 'axios';

export const user_profile = async (authtoken, id, data) => {
  return await axios
    .put(
      `${API_BASE_URL}/user/${id}`, data, { headers: { "auth-token": authtoken } })
}

export const userProfileData = async (authtoken, id) => {
  return await axios
    .get(
      `${API_BASE_URL}/user/${id}`, { headers: { "auth-token": authtoken } })
}

